import { Action, Paragraph, theme } from '@damen/ui'
import styled from 'styled-components'
import LinkVNext from '@/components/Link'

// Styling the a tag is needed otherwise it will never display in the blue color
const FormDisclaimer = styled.span`
  margin-top: 12px;
  color: ${theme.colors.grey};
  font-family: ${theme.fonts.body.style.fontFamily};
  font-size: ${theme.typography.fontSizeTextXSmall}px;

  a {
    color: ${theme.colors.blue} !important;
    text-decoration: underline !important;

    &:hover,
    &:active {
      color: ${theme.colors.marineBlack} !important;
      text-decoration: none !important;
    }
  }
`

const FormSection = styled.section`
  margin: 0 auto;

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

const FormFieldWrapper = styled.div`
  flex: 2;
`

const FormField = styled.div`
  & + & {
    margin-top: ${theme.layout.spacingL};
  }
`

const FormButtonBar = styled.div.withConfig({
  shouldForwardProp: (prop) => !['stickyButton'].includes(prop),
})<{ stickyButton: boolean }>`
  position: ${({ stickyButton }) => (stickyButton ? 'sticky' : 'relative')};
  bottom: 0;
  z-index: 1;
  background: white;
  margin: ${theme.layout.spacingXL} 0 0 0;
  padding: ${({ stickyButton }) => (stickyButton ? `28px 0 ${theme.layout.spacingL} 0` : '0')};

  @media ${theme.legacyMediaQueries.md} {
    bottom: ${({ stickyButton }) => (stickyButton ? '-40px' : '0')};
  }

  &:before {
    content: '';
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    top: -40px;
    left: 0;
    right: 5px;
    height: 40px;
    z-index: 1;
    pointer-events: none;
  }
`

const FormFieldError = styled(Paragraph.Default)`
  margin: ${theme.layout.spacing} 0 0 ${theme.layout.spacingM};
  color: ${theme.colors.blue};
  font-size: ${theme.typography.fontSizeTextSmall}px;
  font-weight: ${theme.typography.fontWeightBold};
`

const InterestedInTextWithLinkAction = styled.div`
  background-color: ${theme.colors.blueIce};
  padding: ${theme.layout.spacingM};
  margin-top: ${theme.layout.spacing};
  font-size: ${theme.typography.fontSizeText}px;
  line-height: ${theme.typography.lineHeightTextSmall};
`

const StyledContactLink = styled(LinkVNext.VNext)`
  ${Action.LinkStyle}

  color: ${theme.colors.blue};
  margin-top: 4px !important;
  width: fit-content;
  margin-bottom: 4px;
`

export {
  FormDisclaimer,
  FormSection,
  FormFieldWrapper,
  FormField,
  FormButtonBar,
  FormFieldError,
  InterestedInTextWithLinkAction,
  StyledContactLink,
}
