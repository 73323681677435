import React, { useCallback, useState } from 'react'
import { Button, Heading, Paragraph, theme } from '@damen/ui'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useMutation } from '@apollo/client'
import Form from '@/components/Forms'
import Modal from '@/components/UI/Modal'

import { useBasePath, useFormattedRouter } from '@/hooks'
import { pushToDataLayer } from '@/lib/gtm'
import { ModalNewsletterContent } from '@/queries/schema.generated'
import {
  NewsletterSubscriptionDocument,
  NewsletterSubscriptionMutation,
} from '@/queries/mutations/NewsletterSubscription.generated'

import SuccessContent from '../SuccessContent'
import { renderRichText } from '@/utils/richTextUtils'
import { NEWSLETTER_QUERYSTRING } from '@/components/Forms/constants'
import { FormContent } from '../styles'

interface Props {
  content?: ModalNewsletterContent
  showModal: boolean
  handleCloseModal: any
}

const mapFormData = (content: any) => ({
  labels: {
    company: content?.company?.label,
    email: content?.email?.label,
    firstname: content?.firstname?.label,
    lastname: content?.lastname?.label,
    subscribe: content?.subscribe?.label,
  },
  placeholders: {
    company: content?.company?.placeholder,
    email: content?.email?.placeholder,
    firstname: content?.firstname?.placeholder,
    lastname: content?.lastname?.placeholder,
  },
  errors: {
    firstname: content?.firstname?.error,
    lastname: content?.lastname?.error,
    email: content?.email?.error,
  },
  validation: {
    email: content?.email?.validation,
  },
  genericValidations: {
    containsUrl: content?.genericValidations?.containsUrl,
  },
  richText: {
    disclaimer: content.disclaimer?.text ? renderRichText(JSON.parse(content.disclaimer?.text)) : undefined,
  },
  ...content,
})

const Newsletter: React.FC<React.PropsWithChildren<Props>> = ({ content, showModal, handleCloseModal }) => {
  const router = useRouter()
  const { url } = useBasePath()
  const [completed, setCompleted] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  const [executeNewsletterSubscription, { data, loading, error }] =
    useMutation<NewsletterSubscriptionMutation>(NewsletterSubscriptionDocument)
  const [handleOnRouteClick] = useFormattedRouter(content?.termsOfUse?.link)

  const isDataSuccess = data?.newsletterSubscription.code === 200 && !error

  const formData = content ? mapFormData(content) : null
  const currentUrl = url ?? ''

  const onFormSubmit = useCallback(
    (values: any) => {
      const payload = () => ({
        ...values,
        currentUrl,
        ...(retryCount > 0 && { retryCount }),
      })

      executeNewsletterSubscription({ variables: { payload: payload() } })
        .then((resp) => {
          if (resp.data?.newsletterSubscription.code === 200) {
            setCompleted(true)
            return pushToDataLayer({ event: 'form_submit', type: 'subscribe_to_newsletter' })
          }
          handleFormError();
        })
        .catch(() => {
          handleFormError()
        })
    },
    [executeNewsletterSubscription, currentUrl, retryCount],
  )

  const handleFormError = () => {
    setCompleted(true);
  }

  const routeClose = useCallback(() => {
    if (router.asPath.includes(NEWSLETTER_QUERYSTRING)) {
      router.back()
    }
  }, [router])

  const closeModal = useCallback(() => {
    handleCloseModal()
    routeClose()
    setCompleted(false);
  }, [handleCloseModal, routeClose])

  const retryForm = useCallback(() => {
    setRetryCount(retryCount + 1)
    window.grecaptcha?.reset()
    setCompleted(false);
  }, [retryCount])

  return (
    content && (
      <Modal.Side isOpen={showModal} onClose={closeModal}>
        <FormContent show={!completed}>
          <TitleWrapper>
            <Title>{content.title}</Title>
            {content.subtitle && <Subtitle>{content.subtitle}</Subtitle>}
          </TitleWrapper>
          <Form.NewsletterForm
            formData={formData}
            isLoading={loading}
            handleOnSubmit={onFormSubmit}
            handleOnError={handleFormError}
          />
          <TermsOfUseWrapper>
            {content.termsOfUse?.text}
            <TermsOfUseLink onClick={handleOnRouteClick} text={content.termsOfUse?.linkText} buttonType="text" />
          </TermsOfUseWrapper>
        </FormContent>

        <SuccessContent image={isDataSuccess ? content.success.image : content.error.image} show={completed}>
          <Heading.HeadingTwo>{isDataSuccess ? content.success.title : content.error.title}</Heading.HeadingTwo>
          <Paragraph.Default>{isDataSuccess ? content.success.text : content.error.text}</Paragraph.Default>
          <Button.Default
            text={isDataSuccess ? content.success.buttonText : content.error.buttonText}
            onClick={isDataSuccess ? closeModal : retryForm}
          />
        </SuccessContent>
      </Modal.Side>
    )
  )
}

const TitleWrapper = styled.div`
  margin: 0 0 32px;
`

const TermsOfUseWrapper = styled.div`
  margin-top: 32px;
  color: ${theme.colors.grey};
  font-family: ${theme.fonts.body.style.fontFamily};
  font-size: 12px;
`

const TermsOfUseLink = styled(Button.Default)`
  margin: 0;
  color: inherit;
  font-size: inherit;
  letter-spacing: normal;
  font-weight: ${theme.typography.fontWeightRegular};

  ::before {
    opacity: 1;
  }
`

const Title = styled(Heading.HeadingFour)`
  margin: 0 0 8px;
`

const Subtitle = styled.div`
  color: ${theme.colors.grey};
  font-family: ${theme.fonts.body.style.fontFamily};
  font-size: 14px;
  font-weight: ${theme.typography.fontWeightRegular};
`

export default Newsletter
