import ContactForm from './Contact'
import DepartmentContactForm from './DepartmentContact'
import DepartmentNewsletterForm from './DepartmentNewsletter'
import DonationForm from './Donation'
import DownloadForm from './Download'
import NewsletterForm from './Newsletter'
import QuoteForm from './Quote'
import FeedbackForm from './Feedback'
import CampaignSmallForm from './CampaignSmall'

export default {
  ContactForm,
  DepartmentContactForm,
  DepartmentNewsletterForm,
  DonationForm,
  DownloadForm,
  NewsletterForm,
  QuoteForm,
  FeedbackForm,
  CampaignSmallForm,
}
