import React, { useRef } from 'react'
import { Button, Form, Icon } from '@damen/ui'
import { Formik, Form as FormWrapper } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'

import { getClientId } from '@/lib/analytics'
import Yup from '@/components/Forms/defaultValidations'
import { getPublicEnv } from '@/utils/env'
import { FormDisclaimer, FormSection, FormFieldWrapper, FormField, FormButtonBar, FormFieldError } from '../styles'
import { useCurrentGoogleClickId } from '@/hooks/googleClickId'
import { ADDRESS_MAX_LENGTH } from '../constants'

interface Props {
  formData: any
  isLoading: boolean
  handleOnSubmit: (values: any) => void
  handleOnError: () => void
  stickyButton?: boolean
}

const shouldRenderOtherAmount = (amount: string) => amount.toLowerCase() === 'other amount'

const FormContainer: React.FC<React.PropsWithChildren<Props>> = ({
  formData,
  isLoading,
  handleOnSubmit,
  handleOnError,
  stickyButton = true,
}) => {
  const reCaptchaRef = useRef(null)
  const DonationSchema = Yup.object().shape({
    amount: Yup.string().required(formData.errors.amount),
    contribution: Yup.string().required(formData.errors.contribution),
    company: Yup.string().noUrl(formData.genericValidations.containsUrl),
    name: Yup.string().required(formData.errors.name).noUrl(formData.genericValidations.containsUrl),
    address: Yup.string()
      .required(formData.errors.address)
      .noUrl(formData.genericValidations.containsUrl)
      .max(ADDRESS_MAX_LENGTH),
    email: Yup.string().email(formData.validations.email).required(formData.errors.email),
    contactMe: Yup.bool(),
    willTransfer: Yup.bool().oneOf([true], formData.errors.willTransfer),
    // It is conflicting between a numberschema and stringschema. The way it is done here works according to the docs.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    otherAmount: Yup.number().when('amount', {
      is: (value: string | number) => value === typeof 'string' && value.toLowerCase() === 'other amount',
      then: () => Yup.number().moreThan(0, formData.validations.otherAmount).required(formData.validations.otherAmount),
    }),
  })
  const { googleClickId } = useCurrentGoogleClickId()

  return (
    <FormSection>
      <Formik
        initialValues={{
          company: '',
          name: '',
          address: '',
          email: '',
          amount: '',
          otherAmount: 0,
          contribution: '',
          contactMe: false,
          willTransfer: false,
          invoice: false,
          gRecaptchaResponse: '',
        }}
        validationSchema={DonationSchema}
        onSubmit={async (values) => {
          try {
            const token = await reCaptchaRef.current.executeAsync()

            const valuesToSend = {
              ...values,
              gRecaptchaResponse: token,
              googleClickId,
            }

            handleOnSubmit({ ...valuesToSend, ...getClientId() }); return;
          } catch (error) {
            handleOnError(); return;
          }
        }}
      >
        {({ dirty, errors, touched, values, handleChange, handleSubmit, setFieldValue }) => {
          return (
            <FormWrapper data-testid="form-donation">
              <FormFieldWrapper>
                <FormField data-testid="donation-form.amount">
                  <Form.Select
                    label={`${formData.labels.amount} *`}
                    options={formData.amountOptions}
                    initialValue={values.amount}
                    onSelect={(option) => {
                      if (!shouldRenderOtherAmount(option.toString())) {
                        void setFieldValue('otherAmount', 0)
                      } else {
                        void setFieldValue('otherAmount', null)
                      }

                      return void setFieldValue('amount', option)
                    }}
                  />
                  {errors.amount && <FormFieldError>{errors.amount}</FormFieldError>}
                </FormField>
                {shouldRenderOtherAmount(values.amount) && (
                  <FormField data-testid="donation-form.otherAmount">
                    <Form.Input
                      error={(errors.otherAmount) || ''}
                      name="otherAmount"
                      placeholder={formData.placeholders.otherAmount}
                      touched={touched.otherAmount}
                      label={`${formData.labels.otherAmount} *`}
                      value={values.otherAmount}
                      pattern="/^([1-9]\d*(\.|\,)\d*|0?(\.|\,)\d*[1-9]\d*|[1-9]\d*)$/gm"
                      onChange={handleChange}
                      type="number"
                    />
                    {/* <ErrorMessage name="otherAmount" render={(msg) => console.log(msg)} /> */}
                  </FormField>
                )}
                <FormField data-testid="donation-form.contribution">
                  <Form.Select
                    label={`${formData.labels.contribution} *`}
                    options={formData.contributionOptions}
                    initialValue={values.contribution}
                    onSelect={(option) => {
                      return void setFieldValue('contribution', option)
                    }}
                  />
                  {errors.contribution && <FormFieldError>{errors.contribution}</FormFieldError>}
                </FormField>
                <FormField data-testid="donation-form.company">
                  <Form.Input
                    error={errors.company || ''}
                    name="company"
                    placeholder={formData.placeholders.company}
                    touched={touched.company}
                    label={formData.labels.company}
                    value={values.company}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField data-testid="donation-form.name">
                  <Form.Input
                    error={errors.name || ''}
                    name="name"
                    placeholder={formData.placeholders.name}
                    touched={touched.name}
                    label={`${formData.labels.name} *`}
                    value={values.name}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <Form.Textarea
                    error={errors.address || ''}
                    name="address"
                    touched={touched.address}
                    label={`${formData.labels.address} *`}
                    value={values.address}
                    placeholder={formData.placeholders.address}
                    onChange={handleChange}
                    maxLength={ADDRESS_MAX_LENGTH}
                  />
                </FormField>
                <FormField data-testid="donation-form.email">
                  <Form.Input
                    error={errors.email || ''}
                    name="email"
                    value={values.email}
                    touched={touched.email}
                    label={`${formData.labels.email} *`}
                    placeholder={formData.placeholders.email}
                    onChange={handleChange}
                    type="email"
                  />
                </FormField>
                <FormField data-testid="donation-form.invoice">
                  <Form.Checkbox
                    background="blue"
                    error={errors.invoice}
                    touched={touched.invoice}
                    name="invoice"
                    label={formData.labels.invoice}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField data-testid="donation-form.willTransfer">
                  <Form.Checkbox
                    background="blue"
                    error={errors.willTransfer}
                    touched={touched.willTransfer}
                    name="willTransfer"
                    label={`${formData.labels.willTransfer} *`}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField data-testid="donation-form.contactMe">
                  <Form.Checkbox
                    background="blue"
                    error={errors.contactMe}
                    touched={touched.contactMe}
                    name="contactMe"
                    label={formData.labels.contactMe}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey={getPublicEnv('NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY')}
                    size="invisible"
                    badge="inline"
                  />
                </FormField>
              </FormFieldWrapper>
              {formData.richText?.disclaimer && <FormDisclaimer>{formData.richText?.disclaimer}</FormDisclaimer>}
              <FormButtonBar stickyButton={stickyButton} data-testid="donation-form.button-submit">
                <Button.Default
                  text={formData.buttonText}
                  onClick={() => handleSubmit()}
                  icon={Icon.LeftChevron}
                  disabled={!dirty || isLoading}
                />
              </FormButtonBar>
            </FormWrapper>
          )
        }}
      </Formik>
    </FormSection>
  )
}

export default FormContainer
