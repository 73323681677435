import React, { useState, useMemo, useCallback } from 'react'
import { useRouter } from 'next/router'
import { Button, Heading, Paragraph, theme } from '@damen/ui'
import styled from 'styled-components'

import { useMutation, useQuery } from '@apollo/client'
import { useBasePath } from '@/hooks'
import Details from '@/components/Forms/Contact/Details'
import Form from '@/components/Forms'
import Modal from '@/components/UI/Modal'
import { pushToDataLayer } from '@/lib/gtm'
import SuccessContent from '../SuccessContent'
import { ModalContactContent } from '@/queries/schema.generated'
import { ContactFormDocument, ContactFormMutation } from '@/queries/mutations/ContactFormMutation.generated'
import { renderRichText } from '@/utils/richTextUtils'
import { CONTACT_QUERYSTRING } from '@/components/Forms/constants'
import { FormContent } from '../styles'
import { GetTranslationsDocument, GetTranslationsQuery } from '@/queries/queries/Global/getTranslations.generated'

interface Props {
  content?: ModalContactContent
  showModal: boolean
  handleCloseModal: any
}

const mapFormData = (content: any) => {
  if (!content) return null
  return {
    options: content?.interestOptions?.map((option: any) => {
      return {
        value: option.value,
        label: option.title,
      }
    }),
    optionsActiveContent: content?.interestOptions
      ?.map((option: any) => {
        return {
          id: option.value,
          activeContent: option.activeContent,
        }
      })
      .filter((option: any) => !!option?.activeContent),
    labels: {
      company: content?.company?.label,
      countries: content?.country?.label,
      countryOfOperation: content?.countryOfOperation?.label,
      email: content?.email?.label,
      firstname: content?.firstname?.label,
      lastname: content?.lastname?.label,
      message: content?.textArea?.label,
      phone: content?.phonenumber?.label,
      subject: content?.interest?.label,
      subscribe: content?.subscribe?.label,
    },
    placeholders: {
      company: content?.company?.placeholder,
      countries: content?.country?.placeholder,
      email: content?.email?.placeholder,
      firstname: content?.firstname?.placeholder,
      lastname: content?.lastname?.placeholder,
      message: content?.textArea?.placeholder,
      phone: content?.phonenumber?.placeholder,
      subject: content?.interest?.placeholder,
    },
    errors: {
      countryOfOperation: content?.countryOfOperation?.error,
      country: content?.country?.error,
      subject: content?.interest?.error,
      email: content?.email?.error,
      firstname: content?.firstname?.error,
      lastname: content?.lastname?.error,
    },
    validations: {
      phone: content?.phonenumber?.validation,
      email: content?.email?.validation,
    },
    extraLabel: {
      phone: content?.phonenumber?.extraLabel,
    },
    genericValidations: {
      containsUrl: content?.genericValidations?.containsUrl,
    },
    richText: {
      disclaimer: content.disclaimer?.text ? renderRichText(JSON.parse(content.disclaimer?.text)) : undefined,
    },
    ...content,
  }
}

const Contact: React.FC<React.PropsWithChildren<Props>> = ({ content, showModal, handleCloseModal }) => {
  const router = useRouter()
  const { url } = useBasePath()
  const [completed, setCompleted] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  const formData = useMemo(() => mapFormData(content), [content])
  const currentUrl = url ?? ''
  const translationData = useQuery<GetTranslationsQuery>(GetTranslationsDocument, { fetchPolicy: 'cache-only' })
  const emailSubject = translationData.data?.site?.emailTranslations?.subject

  const [executeMutation, { data, loading, error }] = useMutation<ContactFormMutation>(ContactFormDocument)

  const isDataSuccess = data?.contactForm.code === 200 && !error

  const onFormSubmit = useCallback(
    (values: any) => {
      const payload = () => ({
        ...values,
        currentUrl,
        ...(retryCount > 0 && { retryCount }),
      })

      executeMutation({
        variables: { id: values.subject, payload: payload() },
      })
        .then((resp) => {
          if (resp.data?.contactForm.code === 200) {
            setCompleted(true)
            return pushToDataLayer({ event: 'form_submit', 'contact-interest': values.subject, type: 'get_in_contact' })
          }

          handleFormError();
        })
        .catch(() => {
          handleFormError()
        })
    },
    [executeMutation, currentUrl, retryCount],
  )

  const handleFormError = () => {
    setCompleted(true);
  }

  const closeModal = useCallback(() => {
    handleCloseModal()
    if (router.asPath.includes(CONTACT_QUERYSTRING)) {
      router.back()
    }
    setCompleted(false);
  }, [handleCloseModal, router])

  const retryForm = useCallback(() => {
    setRetryCount(retryCount + 1)
    window.grecaptcha?.reset()
    setCompleted(false);
  }, [retryCount])

  return (
    content && (
      <Modal.Side
        isOpen={showModal}
        testId="modal-contact"
        onClose={closeModal}
        extraPanelContent={
          <Details
            contactDetails={content.contactDetails}
            showBowWave={content.showBowWave}
            emailSubject={emailSubject}
          />
        }
      >
        <FormContent show={!completed}>
          <TitleWrapper>
            <Title>{content.title}</Title>
            {content.subtitle && <Subtitle>{content.subtitle}</Subtitle>}
          </TitleWrapper>
          <Form.ContactForm
            formData={formData}
            isLoading={loading}
            handleOnSubmit={onFormSubmit}
            handleOnError={handleFormError}
          />
        </FormContent>

        <SuccessContent
          image={isDataSuccess ? content.success.image : content.error.image}
          showBowWave={content.showBowWave}
          testId={isDataSuccess ? 'modal-contact.success' : 'modal-contact.error'}
          show={completed}
        >
          <Heading.HeadingTwo>{isDataSuccess ? content.success.title : content.error.title}</Heading.HeadingTwo>
          <Paragraph.Default>{isDataSuccess ? content.success.text : content.error.text}</Paragraph.Default>
          <Button.Default
            testId="button-contact-close"
            text={isDataSuccess ? content.success.buttonText : content.error.buttonText}
            onClick={isDataSuccess ? closeModal : retryForm}
          />
        </SuccessContent>
      </Modal.Side>
    )
  )
}

const TitleWrapper = styled.div`
  margin: 0 0 32px;
`

const Title = styled(Heading.HeadingFour)`
  margin: 0 0 8px;
`

const Subtitle = styled.div`
  color: ${theme.colors.grey};
  font-family: ${theme.fonts.body.style.fontFamily};
  font-size: 14px;
  font-weight: ${theme.typography.fontWeightRegular};
`

export default Contact
