import React from 'react'
import { Icon, theme } from '@damen/ui'
import LinkVNext from '@/components/Link'
import {
  Address,
  AddressContent,
  Detail,
  Footer,
  Inner,
  Label,
  Link,
  StyledObfuscate,
  Subtitle,
  Title,
  TitleWrapper,
  Value,
} from '../../contactFormDetailStyles'

interface Props {
  contactDetails: any
  showBowWave?: boolean
  emailSubject?: string
}

const Details: React.FC<React.PropsWithChildren<Props>> = ({ contactDetails, showBowWave = true, emailSubject }) => {
  const { title, subtitle } = contactDetails

  return (
    <Inner>
      {title && (
        <TitleWrapper>
          <Title>{title}</Title>
          {subtitle && <Title>{subtitle}</Title>}
        </TitleWrapper>
      )}
      {contactDetails && (
        <>
          {contactDetails.phonenumber.label && contactDetails.phonenumber.value && (
            <Detail key={contactDetails.phonenumber.label}>
              <Label>{contactDetails.phonenumber.label}</Label>
              <Value>
                <Link href={`tel:${contactDetails.phonenumber.value}`} rel="noreferrer">
                  {contactDetails.phonenumber.value}
                </Link>
              </Value>
            </Detail>
          )}
          {contactDetails.email.label && contactDetails.email.value && (
            <Detail key={contactDetails.email.label}>
              <Label>{contactDetails.email.label}</Label>
              <Value>
                <StyledObfuscate email={contactDetails.email.value} headers={{ subject: emailSubject }} />
              </Value>
            </Detail>
          )}
          <AddressContent>
            {contactDetails.address && <Subtitle>{contactDetails.address.title}</Subtitle>}
            <Address>
              {contactDetails.address.value.split('\n').map((value: string) => (
                <Value key={value}>{value}</Value>
              ))}
            </Address>
            <LinkVNext.VNext
              link={contactDetails.link}
              text={contactDetails.linkText}
              type="contentlink"
              size="small"
            />
          </AddressContent>
        </>
      )}
      {showBowWave && (
        <Footer>
          <Icon.BowWaveSmall fill={theme.colors.orange} width={99} height={50} />
        </Footer>
      )}
    </Inner>
  )
}

export default Details
