import React, { useState, useMemo, useCallback } from 'react'
import { useRouter } from 'next/router'
import { Button, Heading, Paragraph, theme } from '@damen/ui'
import styled from 'styled-components'
import { render } from 'storyblok-rich-text-react-renderer'

import { useMutation } from '@apollo/client'
import { useBasePath } from '@/hooks'
import Form from '@/components/Forms'
import Modal from '@/components/UI/Modal'
import { pushToDataLayer } from '@/lib/gtm'
import SuccessContent from '../SuccessContent'
import { ModalDonationContent } from '@/queries/schema.generated'
import { DonationFormDocument, DonationFormMutation } from '@/queries/mutations/DonationFormMutation.generated'
import { DONATION_QUERYSTRING, FORMTYPE } from '@/components/Forms/constants'
import { FormContent } from '../styles'

interface Props {
  id: string
  content?: ModalDonationContent
  showModal: boolean
  handleCloseModal: any
}

const mapFormData = (content: any) => {
  if (!content) return null
  return {
    amountOptions: content?.amountOptions,
    contributionOptions: content?.contributionOptions,
    labels: {
      amount: content?.amount?.label,
      otherAmount: content?.otherAmount?.label,
      contribution: content?.contribution?.label,
      company: content?.company?.label,
      name: content?.name?.label,
      address: content?.address?.label,
      email: content?.email?.label,
      contactMe: content?.contactMe?.label,
      willTransfer: content?.willTransfer?.label,
      invoice: content?.invoice?.label,
    },
    placeholders: {
      amount: content?.country?.placeholder,
      otherAmount: content?.otherAmount?.placeholder,
      contribution: content?.contribution?.label,
      company: content?.company?.placeholder,
      name: content?.name?.placeholder,
      address: content?.address?.placeholder,
      email: content?.email?.placeholder,
    },
    errors: {
      amount: content?.amount?.error,
      otherAmount: content?.otherAmount?.error,
      contribution: content?.contribution?.error,
      name: content?.name?.error,
      address: content?.address?.error,
      email: content?.email?.error,
      willTransfer: content?.willTransfer?.error,
    },
    validations: {
      email: content?.email?.validation,
      otherAmount: content?.otherAmount?.validation,
    },
    genericValidations: {
      containsUrl: content?.genericValidations?.containsUrl,
    },
    richText: {
      disclaimer: content?.disclaimer?.text ? render(JSON.parse(content?.disclaimer?.text)) : undefined,
    },
    ...content,
  }
}

const Donation: React.FC<React.PropsWithChildren<Props>> = ({
  id: sidebarId,
  content,
  showModal,
  handleCloseModal,
}) => {
  const router = useRouter()
  const { url } = useBasePath()
  const [completed, setCompleted] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  const formData = useMemo(() => mapFormData(content), [content])
  const currentUrl = url ?? ''

  const [executeMutation, { data, loading, error }] = useMutation<DonationFormMutation>(DonationFormDocument)

  const isDataSuccess = data?.donationForm.code === 200 && !error

  const onFormSubmit = useCallback(
    (values: any) => {
      const payload = () => ({
        ...values,
        currentUrl,
        ...(retryCount > 0 && { retryCount }),
      })
      executeMutation({
        variables: { sidebarId, payload: payload() },
      })
        .then((resp) => {
          if (resp.data?.donationForm.code === 200) {
            setCompleted(true)
            return pushToDataLayer({ event: 'form_submit', 'contact-interest': values.subject, type: 'donation' })
          }

          handleFormError();
        })
        .catch(() => {
          handleFormError()
        })
    },
    [executeMutation, sidebarId, currentUrl, retryCount],
  )

  const handleFormError = () => {
    setCompleted(true);
  }

  const closeModal = useCallback(() => {
    handleCloseModal()
    if (router.asPath.includes(DONATION_QUERYSTRING)) {
      localStorage.removeItem(FORMTYPE)
      router.back()
    }
    setCompleted(false);
  }, [handleCloseModal, router])

  const retryForm = useCallback(() => {
    setRetryCount(retryCount + 1)
    window.grecaptcha?.reset()
    setCompleted(false);
  }, [retryCount])

  return (
    content && (
      <Modal.Side isOpen={showModal} testId="donation-modal" onClose={closeModal}>
        <FormContent show={!completed}>
          <TitleWrapper>
            <Title>{content.title}</Title>
            {content.subtitle && <Subtitle>{content.subtitle}</Subtitle>}
          </TitleWrapper>
          <Form.DonationForm
            formData={formData}
            isLoading={loading}
            handleOnSubmit={onFormSubmit}
            handleOnError={handleFormError}
          />
        </FormContent>

        <SuccessContent
          image={isDataSuccess ? content.success.image : content.error.image}
          showBowWave={content.showBowWave}
          show={completed}
        >
          <Heading.HeadingTwo>{isDataSuccess ? content.success.title : content.error.title}</Heading.HeadingTwo>
          <Paragraph.Default>{isDataSuccess ? content.success.text : content.error.text}</Paragraph.Default>
          <Button.Default
            testId="button-donation-close"
            text={isDataSuccess ? content.success.buttonText : content.error.buttonText}
            onClick={isDataSuccess ? closeModal : retryForm}
          />
        </SuccessContent>
      </Modal.Side>
    )
  )
}

const TitleWrapper = styled.div`
  margin: 0 0 32px;
`

const Title = styled(Heading.HeadingFour)`
  margin: 0 0 8px;
`

const Subtitle = styled.div`
  color: ${theme.colors.grey};
  font-family: ${theme.fonts.body.style.fontFamily};
  font-size: 14px;
  font-weight: ${theme.typography.fontWeightRegular};
`

export default Donation
