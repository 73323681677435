import React, { ComponentProps, ReactNode, useCallback, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { makeVar, useReactiveVar } from '@apollo/client'
import { theme, Icon } from '@damen/ui'

import getPublicIdFromCloudinaryUrl from '@/utils/getPublicIdFromCloudinaryUrl'

import preloadVideo from '@/utils/preloadVideo'
import { getPreloadUrl } from './HighlightVideo'

import IntegratedServicesHighlightsImage from './IntegratedServicesHighlightsImage'
import IntegratedServicesHighlightsVideo from './IntegratedServicesHighlightsVideo'
import mergeRefs from '@/utils/mergeRefs'
import PortfolioHighlightsPreloading from './PortfolioHighlightsPreloading'
import { HotSpot } from '../types'
import IntegratedServicesHighlightSecond from './IntegratedServicesHighlightSecond'
import {
  HighlightCloseButton,
  HighlightContent,
  HighlightContentMobile,
  Positioner,
  SubTitle,
  Title,
  activeHighlightCss,
} from '../styles'

/**
 * This var represents whether the highlight video is "presenting" the feature
 * shown by its final frame.
 */
export const highlightVideoPresentingVar = makeVar(false)

const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'hasActiveHighlight',
})<{ hasActiveHighlight?: boolean }>`
  position: relative;
  overflow: hidden;
  z-index: 0;
  width: 100%;
  padding: ${theme.spacing.x10}px 0;
  background: ${theme.colors.marineBlack};

  ${({ hasActiveHighlight }) =>
    hasActiveHighlight &&
    css`
      ${activeHighlightCss}
    `}

  @media screen and (${theme.mediaQueries.mediaQueryTablet}) {
    min-height: auto;
  }
`

const VisualContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'hideImageOverlay',
})<{ hideImageOverlay?: boolean }>`
  position: relative;
  padding-top: 56.25%; // 16:9

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${theme.colors.marineBlack};
    opacity: 0.7;

    animation: ${keyframes`
      to {
        opacity: 0;
      }
    `} 800ms ease-in-out forwards;
    animation-delay: 600ms;
  }
`

interface ImageInfo {
  src: string
  alt?: string
}

interface Props extends Omit<ComponentProps<typeof Container>, 'children' | 'title' | 'sectionIndicatorVariant'> {
  title: ReactNode
  subTitle: ReactNode
  image: ImageInfo
  buttons: HotSpot[]
  mobileMargins?: number
}

const IntegratedServicesHighlights = ({ title, subTitle, image, buttons, mobileMargins = 0, ...others }: Props) => {
  const [ref, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })
  const containerRef = useRef<HTMLDivElement>(null)
  const highlightVisualRef = useRef<HTMLDivElement>(null)

  const [loadingHighlightProgress, setLoadingHighlightProgress] = useState<[HotSpot, number] | undefined>()

  const [activeHighlight, setActiveHighlight] = useState<HotSpot | undefined>()
  const [nestedHighlightActive, setNestedHighlightActive] = useState<boolean>()

  const openHighlight = async (locationBlock: HotSpot) => {
    if (loadingHighlightProgress) {
      // Already loading a highlight
      return
    }

    try {
      await preloadVideo(getPreloadUrl(getPublicIdFromCloudinaryUrl(locationBlock.video)), (progress) => {
        setLoadingHighlightProgress([locationBlock, progress])
      })
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to preload highlights video due to error:', err)
    } finally {
      setLoadingHighlightProgress(undefined)
    }

    setActiveHighlight(locationBlock)
  }

  const [isClosing, setIsClosing] = useState(false)
  const handleFinishPlaying = useCallback(() => {
    if (isClosing) {
      setIsClosing(false)
      setActiveHighlight(undefined)
      return
    }

    highlightVideoPresentingVar(true)
  }, [isClosing])
  const handleClose = useCallback(() => {
    setIsClosing(true)
    highlightVideoPresentingVar(false)
  }, [])
  const isPresenting = useReactiveVar(highlightVideoPresentingVar)

  return (
    <Container
      data-testid="highlightsBlok"
      ref={mergeRefs(ref, containerRef)}
      style={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ['--mobile-margins' as any]: `calc(${mobileMargins / 100} * 100vw)`,
      }}
      {...others}
    >
      <Positioner ref={highlightVisualRef}>
        <VisualContainer>
          <IntegratedServicesHighlightsImage<HotSpot>
            image={image}
            buttons={buttons}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onButtonClick={openHighlight}
            showButtons={!activeHighlight && inView}
            getLoadingProgress={(button) => {
              if (!loadingHighlightProgress || button !== loadingHighlightProgress[0]) {
                return undefined
              }

              return loadingHighlightProgress[1]
            }}
          />

          {activeHighlight && (
            <IntegratedServicesHighlightsVideo
              video={activeHighlight.video}
              reverse={isClosing}
              onFinishPlaying={handleFinishPlaying}
            />
          )}
        </VisualContainer>

        {!activeHighlight && !isPresenting && (
          <>
            <HighlightContent>
              <Title>{title}</Title>
              <SubTitle>{subTitle}</SubTitle>
            </HighlightContent>

            <HighlightContentMobile>
              <Title>{title}</Title>
            </HighlightContentMobile>
          </>
        )}

        {activeHighlight && isPresenting && (
          <>
            <HighlightCloseButton
              onClick={handleClose}
              data-testid="highlightsBlok.close"
              style={{ zIndex: nestedHighlightActive ? 1 : 2 }}
            >
              <Icon.RightChevron width={14} height={14} />
            </HighlightCloseButton>

            {/* Only 1 hihglight can be added (at the moment) */}
            {activeHighlight.highlight[0] && (
              <IntegratedServicesHighlightSecond
                id={activeHighlight.highlight[0].id}
                title={activeHighlight.highlight[0].title}
                subTitle={activeHighlight.highlight[0].subtitle}
                buttons={activeHighlight.highlight[0].buttons}
                setNestedHighlightActive={setNestedHighlightActive}
              />
            )}
          </>
        )}
      </Positioner>

      <PortfolioHighlightsPreloading buttons={buttons} />
    </Container>
  )
}

export default IntegratedServicesHighlights
