import React from 'react'
import { theme } from '@damen/ui'
import { LinkWrapper, LogoWrapper } from '../styles'
import { Logo } from '@/components/Logo'

interface Props {
  isMenuOpen?: boolean
  currentPage?: string
  variant: 'light' | 'dark'
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SiteNavigationLogo: React.FC<React.PropsWithChildren<Props>> = ({ isMenuOpen, currentPage, variant }) => {
  // TODO: Fix hydration error
  // if (currentPage === '/') {
  //   return (
  //     <LogoWrapper isOpen={isMenuOpen}>
  //       <Logo fill={variant === 'dark' || isMenuOpen ? theme.colors.blue : theme.colors.white} width={200} />
  //     </LogoWrapper>
  //   )
  // }

  return (
    <LogoWrapper isOpen={isMenuOpen}>
      <LinkWrapper type="link" link="/">
        <Logo fill={variant === 'dark' || isMenuOpen ? theme.colors.blue : theme.colors.white} width={200} />
      </LinkWrapper>
    </LogoWrapper>
  )
}

export default SiteNavigationLogo
