import styled from 'styled-components'
import React, { ComponentProps, forwardRef, memo } from 'react'

import makeCloudinaryVideoUrl from '@/utils/makeCloudinaryVideoUrl'
import CloudinaryVideo from './CloudinaryVideo'

const StyledVideo = styled(CloudinaryVideo)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const transformations = [
  // By default a keyframe is inserted in videos about once every 2
  // seconds. When reversing, the video doesn't actually animate until
  // it hits a keyframe. To have the video animate within an
  // imperceptible time, we need to have a keyframe somewhat close to
  // the end of the video.
  // A value above 0.2 may cause noticable jumping of the video.
  `ki_0.2`, // in seconds
]

// When preloading we assume mp4 since it's supported all major browsers
export const getPreloadUrl = (publicId: string) =>
  makeCloudinaryVideoUrl({
    publicId,
    format: 'mp4',
    transformations,
  })

// Cloudinary seems to have trouble with applying the transformations to webm
const formatBlacklist = ['webm']

interface Props extends ComponentProps<typeof StyledVideo> {
  publicId: string
}

// eslint-disable-next-line react/display-name
const HighlightVideo = forwardRef<HTMLVideoElement, Props>(({ publicId, ...others }, ref) => (
  <StyledVideo
    publicId={publicId}
    autoPlay
    // Needed for autoPlay to work in modern browsers
    muted
    // Needed for Safari to auto play
    playsInline
    // Hide Edge intrusive PIP button
    disablePictureInPicture
    transformations={transformations}
    formatBlacklist={formatBlacklist}
    ref={ref}
    {...others}
  />
))

// video restarts the video each time it's rerendered, so we need to memoize
// it to avoid it from rerendering unnnecessarily.
export default memo(HighlightVideo)
