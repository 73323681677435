import React from 'react'
import styled from 'styled-components'
import { theme, Heading } from '@damen/ui'
import { typography } from '@damen/ui/lib/cjs/styles'
import Link from '@/components/Link'
import { StoryblokLink } from '@/queries/schema.generated'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${theme.colors.marineBlack};
  font-size: ${typography.fontSizeTextSmall}px;
`

const Title = styled(Heading.HeadingFour)`
  display: none;
  font-size: ${typography.fontSizeTextSmall}px;
  font-weight: ${typography.fontWeightRegular};
  margin: 0 ${theme.spacing.x05}px 0 0;

  @media ${theme.mediaQueries.mediaQueryTablet} {
    display: inline-block;
  }
`

const StyledLink = styled(Link.VNext)`
  // spacing between link and subtitle
  margin-right: 1px;
  color: ${theme.colors.orange};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

interface Props {
  title: string
  subtitle: string
  link: StoryblokLink
  linkText: string
}

const NeedHelp: React.FC<React.PropsWithChildren<Props>> = ({ title, subtitle, link, linkText }) => (
  <Wrapper>
    <Title>{title}</Title>
    <span>
      <StyledLink type="raw" link={link} text={linkText}>{linkText}</StyledLink>
      {subtitle}
    </span>
  </Wrapper>
)

export default NeedHelp
