import React, { useRef, useState } from 'react'
import { Button, Form, Icon, Paragraph, theme } from '@damen/ui'
import { Formik, Form as FormWrapper, ErrorMessage } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import styled from 'styled-components'

import { useIsoCountries } from '@/hooks'
import { getClientId } from '@/lib/analytics'
import Yup from '@/components/Forms/defaultValidations'
import { MESSAGE_MAX_LENGTH, PHONENUMBER_MAXLENGTH, PHONENUMBER_REGEX } from '@/components/Forms/constants'
import { getPublicEnv } from '@/utils/env'
import { useCurrentGoogleClickId } from '@/hooks/googleClickId'
import { InterestedInTextWithLinkAction, StyledContactLink } from '../styles'

interface Props {
  formData: any
  isLoading: boolean
  handleOnSubmit: (values: any) => void
  handleOnError: () => void
}

const shouldRenderCountryOfOperations = (subject: string) => subject.toLowerCase() === 'new vessels'

const FormContainer: React.FC<React.PropsWithChildren<Props>> = ({
  formData,
  isLoading,
  handleOnSubmit,
  handleOnError,
}) => {
  const { countryList } = useIsoCountries()
  const reCaptchaRef = useRef(null)
  const [interestActiveContent, setInterestActiveContent] = useState(null)
  const ContactSchema = Yup.object().shape({
    email: Yup.string().email(formData.validations.email).required(formData.errors.email),
    firstname: Yup.string().required(formData.errors.firstname).noUrl(formData.genericValidations.containsUrl),
    lastname: Yup.string().required(formData.errors.lastname).noUrl(formData.genericValidations.containsUrl),
    phone: Yup.string().matches(PHONENUMBER_REGEX, formData.validations.phone),
    company: Yup.string().noUrl(formData.genericValidations.containsUrl),
    message: Yup.string().noUrl(formData.genericValidations.containsUrl).max(MESSAGE_MAX_LENGTH),
    subject: Yup.string().required(formData.errors.subject),
    country: Yup.string().required(formData.errors.country),
  })
  const { googleClickId } = useCurrentGoogleClickId()
  const interestedInShouldAutoselected = formData.options.length === 1
  const interestedIninitialValue: string = interestedInShouldAutoselected ? formData.options[0]?.value : ''

  return (
    <Container>
      <Formik
        initialValues={{
          country: '',
          countryOfOperation: null,
          email: '',
          company: '',
          firstname: '',
          lastname: '',
          phone: '',
          subject: interestedInShouldAutoselected ? interestedIninitialValue : '',
          interestedIn: interestedInShouldAutoselected ? interestedIninitialValue : '',
          message: '',
          subscribeNewsletter: false,
          gRecaptchaResponse: '',
        }}
        validationSchema={ContactSchema}
        onSubmit={async (values, actions) => {
          try {
            const token = await reCaptchaRef.current.executeAsync()

            if (!values.countryOfOperation && shouldRenderCountryOfOperations(values.subject)) {
              actions.setErrors({ countryOfOperation: formData.errors.countryOfOperation }); return;
            }

            const valuesToSend = { ...values, gRecaptchaResponse: token }

            handleOnSubmit({ ...valuesToSend, ...getClientId(), googleClickId }); return;
          } catch (error) {
            handleOnError(); return;
          }
        }}
      >
        {({ dirty, errors, touched, values, handleChange, handleSubmit, setFieldValue }) => {
          return (
            <FormWrapper data-testid="form-contact">
              <FieldWrapper>
                {!interestedInShouldAutoselected && (
                  <Field>
                    <Form.Select
                      label={`${formData.labels.subject} *`}
                      options={formData.options}
                      initialValue={values.subject}
                      onSelect={(option) => {
                        void setFieldValue('interestedIn', option)
                        if (!shouldRenderCountryOfOperations(option.toString())) {
                          void setFieldValue('countryOfOperation', null)
                        }
                        setInterestActiveContent(
                          formData.optionsActiveContent.find((item: any) => item.id === values.subject),
                        )

                        return void setFieldValue('subject', option)
                      }}
                    />
                    {errors.subject && <Error>{errors.subject}</Error>}
                    {interestActiveContent && (
                      <InterestedInTextWithLinkAction>
                        {interestActiveContent.activeContent.text}{' '}
                        <StyledContactLink type="raw" link={`tel:${interestActiveContent.activeContent.contact.phone}`}>
                          {interestActiveContent.activeContent.contact.phone}
                        </StyledContactLink>
                      </InterestedInTextWithLinkAction>
                    )}
                  </Field>
                )}
                <Field>
                  <Form.Input
                    error={errors.firstname || ''}
                    name="firstname"
                    placeholder={formData.placeholders.firstname}
                    touched={touched.firstname}
                    label={`${formData.labels.firstname} *`}
                    value={values.firstname}
                    onChange={handleChange}
                  />
                </Field>
                <Field>
                  <Form.Input
                    error={errors.lastname || ''}
                    name="lastname"
                    placeholder={formData.placeholders.lastname}
                    touched={touched.lastname}
                    label={`${formData.labels.lastname} *`}
                    value={values.lastname}
                    onChange={handleChange}
                  />
                </Field>
                <Field>
                  <Form.Input
                    error={errors.email || ''}
                    name="email"
                    value={values.email}
                    touched={touched.email}
                    label={`${formData.labels.email} *`}
                    placeholder={formData.placeholders.email}
                    onChange={handleChange}
                    type="email"
                  />
                </Field>
                <Field>
                  <Form.Select
                    label={`${formData.labels.countries} *`}
                    options={countryList}
                    initialValue={values.country}
                    onSelect={(option) => void setFieldValue('country', option)}
                  />
                  <ErrorMessage name="country" render={(msg) => <Error>{msg}</Error>} />
                </Field>
                {shouldRenderCountryOfOperations(values.subject) && (
                  <Field>
                    <Form.Select
                      label={`${formData.labels.countryOfOperation} *`}
                      options={countryList}
                      initialValue={values.countryOfOperation}
                      onSelect={(option) => void setFieldValue('countryOfOperation', option)}
                    />
                    <ErrorMessage name="countryOfOperation" render={(msg) => <Error>{msg}</Error>} />
                  </Field>
                )}
                <Field>
                  <Form.Input
                    error={errors.phone || ''}
                    name="phone"
                    touched={touched.phone}
                    label={formData.labels.phone}
                    extraLabel={formData.extraLabel.phone}
                    value={values.phone}
                    placeholder={formData.placeholders.phone}
                    onChange={handleChange}
                    maxLength={PHONENUMBER_MAXLENGTH}
                  />
                </Field>
                <Field>
                  <Form.Input
                    error={errors.company || ''}
                    name="company"
                    touched={touched.company}
                    label={formData.labels.company}
                    extraLabel={formData.extraLabel.company}
                    value={values.company}
                    placeholder={formData.placeholders.company}
                    onChange={handleChange}
                  />
                </Field>
                <Field>
                  <Form.Textarea
                    error={errors.message || ''}
                    name="message"
                    touched={touched.message}
                    label={formData.labels.message}
                    value={values.message}
                    placeholder={formData.placeholders.message}
                    onChange={handleChange}
                    maxLength={MESSAGE_MAX_LENGTH}
                  />
                </Field>
                {formData.labels.subscribe && (
                  <Field>
                    <Form.Checkbox
                      background="blue"
                      error={errors.subscribeNewsletter}
                      touched={touched.subscribeNewsletter}
                      name="subscribeNewsletter"
                      label={formData.labels.subscribe}
                      onChange={handleChange}
                    />
                  </Field>
                )}
                <Field>
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey={getPublicEnv('NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY')}
                    size="invisible"
                    badge="inline"
                  />
                </Field>
              </FieldWrapper>
              {formData.richText?.disclaimer && <Disclaimer>{formData.richText?.disclaimer}</Disclaimer>}
              <ButtonBar>
                <Button.Default
                  testId="button-form-submit"
                  text={formData.buttonText}
                  onClick={() => handleSubmit()}
                  icon={Icon.LeftChevron}
                  disabled={!dirty || isLoading}
                />
              </ButtonBar>
            </FormWrapper>
          )
        }}
      </Formik>
    </Container>
  )
}

const Container = styled.section`
  margin: 0 auto;

  @media ${theme.legacyMediaQueries.md} {
    height: 100%;
  }

  form {
    display: flex;
    flex-direction: column;
  }
`

const FieldWrapper = styled.div`
  flex: 2;
`

export const Field = styled.div`
  & + & {
    margin-top: ${theme.layout.spacingL};
  }
`

const ButtonBar = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 1;
  background: white;
  margin: ${theme.layout.spacingXL} 0 0 -${theme.layout.spacingL};
  padding: 28px 0 ${theme.layout.spacingL} ${theme.layout.spacingXL};

  @media ${theme.legacyMediaQueries.md} {
    bottom: -40px;
  }

  &:before {
    content: '';
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    top: -40px;
    left: 0;
    right: 5px;
    height: 40px;
    z-index: 1;
    pointer-events: none;
  }
`

const Error = styled(Paragraph.Default)`
  margin: ${theme.layout.spacing} 0 0 ${theme.layout.spacingM};
  color: ${theme.colors.blue};
  font-size: ${theme.typography.fontSizeTextSmall}px;
  font-weight: ${theme.typography.fontWeightBold};
`

const Disclaimer = styled.span`
  margin-top: 12px;
  color: ${theme.colors.grey};
  font-family: ${theme.fonts.body.style.fontFamily};
  font-size: ${theme.typography.fontSizeTextXSmall}px;
`

export default FormContainer
