import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Heading, Paragraph } from '@damen/ui'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useMutation } from '@apollo/client'
import Form from '@/components/Forms'
import { pushToDataLayer } from '@/lib/gtm'
import Modal from '@/components/UI/Modal'
import { useBasePath } from '@/hooks'
import { CreateProspectDocument, CreateProspectMutation } from '@/queries/mutations/CreateProspect.generated'
import SuccessContent from '../SuccessContent'
import { DownloadFormLocalStorageDataProps, Props } from '@/components/Forms/Download/types'
import { openUrlInNewTab } from '@/utils/popup'
import { createProxyUrl } from '@/utils/createProxyUrl'
import { renderRichText } from '@/utils/richTextUtils'
import Link from '@/components/Link'
import { DOWNLOAD_QUERYSTRING } from '@/components/Forms/constants'
import { FormContent } from '../styles'

const DOWNLOAD_FORM_LOCAL_STORAGE_KEY = 'DamenDownloadForm'

const mapFormData = (content: any) => {
  if (!content) return null
  return {
    labels: {
      buttonText: content?.buttonText,
      email: content?.email?.label,
      firstname: content?.firstname?.label,
      lastname: content?.lastname?.label,
      subscribe: content?.subscribe?.label,
    },
    placeholders: {
      email: content?.email?.placeholder,
      firstname: content?.firstname?.placeholder,
      lastname: content?.lastname?.placeholder,
    },
    errors: {
      email: content?.email?.error,
      firstname: content?.firstname?.error,
      lastname: content?.lastname?.error,
    },
    validations: {
      email: content?.email?.validation,
    },
    genericValidations: {
      containsUrl: content?.genericValidations?.containsUrl,
    },
    richText: {
      disclaimer: content?.disclaimer?.text ? renderRichText(JSON.parse(content?.disclaimer?.text)) : undefined,
    },
    ...content,
  }
}

const Download: React.FC<React.PropsWithChildren<Props>> = ({ modalDownload, selectedModal, handleCloseModal }) => {
  const router = useRouter()
  const { url } = useBasePath()
  const [completed, setCompleted] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  const [initialFormValues, setInitialFormValues] = useState<DownloadFormLocalStorageDataProps>(null)
  const storedFormData = localStorage.getItem(DOWNLOAD_FORM_LOCAL_STORAGE_KEY)

  const saveDataInLocalStorage = (data: DownloadFormLocalStorageDataProps) => {
    localStorage.setItem(DOWNLOAD_FORM_LOCAL_STORAGE_KEY, JSON.stringify(data))
  }

  const [executeProspectRequest, { data, loading }] = useMutation<CreateProspectMutation>(CreateProspectDocument)
  const currentUrl = url ?? ''

  const { data: downloadData } = selectedModal ?? {}

  const onFormSubmit = useCallback(
    (values: any) => {
      const payload = () => ({
        ...values,
        currentUrl,
        product: downloadData?.product,
        ...(retryCount > 0 && { retryCount }),
      })

      executeProspectRequest({
        variables: { payload: payload() },
      })
        .then((resp) => {
          if (resp.data?.createProspect.code === 200) {
            setCompleted(true)
            const downloadUrl = downloadData?.link.url
            if (downloadUrl) {
              openUrlInNewTab(createProxyUrl(downloadUrl))
            } else {
              console.warn('url is null')
            }
            return pushToDataLayer({
              event: 'form_submit',
              product: downloadData?.title || '',
              type: 'download_productsheet',
            })
          }
          handleFormError();
        })
        .catch(() => {
          handleFormError()
        })
      saveDataInLocalStorage(values)
    },
    [
      executeProspectRequest,
      currentUrl,
      downloadData?.product,
      downloadData?.link.url,
      downloadData?.title,
      retryCount,
    ],
  )

  const handleFormError = () => {
    setCompleted(true);
  }

  const formData = useMemo(() => mapFormData(modalDownload), [modalDownload])

  const onModalClose = useCallback(() => {
    handleCloseModal()
    if (router.asPath.includes(DOWNLOAD_QUERYSTRING)) {
      router.back()
    }
    setCompleted(false);
  }, [handleCloseModal, router])

  const retryForm = useCallback(() => {
    setRetryCount(retryCount + 1)
    window.grecaptcha?.reset()
    setCompleted(false);
  }, [retryCount])

  const isRequestSuccess = data?.createProspect.code === 200

  useEffect(() => {
    if (storedFormData) {
      setInitialFormValues(JSON.parse(storedFormData))
    }
  }, [storedFormData])

  return (
    modalDownload && (
      <Modal.Side isOpen={selectedModal?.type === 'download'} onClose={onModalClose} testId="modal-download">
        <FormContent show={!completed}>
          <Title>
            {modalDownload.title} &ldquo;{downloadData?.title}&rdquo;
          </Title>
          <Form.DownloadForm
            formData={formData}
            isLoading={loading}
            handleOnSubmit={onFormSubmit}
            handleOnError={handleFormError}
            initialValues={initialFormValues}
          />
        </FormContent>

        <SuccessContent
          image={isRequestSuccess ? modalDownload.success.image : modalDownload.success.image}
          testId={isRequestSuccess ? 'modal-download.success' : 'modal-download.error'}
          show={completed}
        >
          <Heading.HeadingTwo>
            {isRequestSuccess ? modalDownload.success.title : modalDownload.error.title}
          </Heading.HeadingTwo>
          <Paragraph.Default>
            {isRequestSuccess ? modalDownload.success.text : modalDownload.error.text}
          </Paragraph.Default>
          {isRequestSuccess && modalDownload.success?.linkText && (
            <Paragraph.Default>
              {modalDownload.success.linkTextPrefix && `${modalDownload.success.linkTextPrefix} `}
              <StyledContentLink
                type="contentlink"
                text={modalDownload.success.linkText}
                link={downloadData?.link.url}
                icon={() => <></>}
              />
            </Paragraph.Default>
          )}
          <Button.Default
            text={isRequestSuccess ? modalDownload.success.buttonText : modalDownload.error.buttonText}
            onClick={isRequestSuccess ? onModalClose : retryForm}
          />
        </SuccessContent>
      </Modal.Side>
    )
  )
}

const Title = styled(Heading.HeadingFour)`
  margin-top: 0;
`

const StyledContentLink = styled(Link.VNext)`
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

export default Download
