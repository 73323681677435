import React from 'react'
import Head from 'next/head'

import { getPreloadUrl } from './HighlightVideo'
import getPublicIdFromCloudinaryUrl from '@/utils/getPublicIdFromCloudinaryUrl'

interface Props {
  buttons: { video?: string }[]
}

const PortfolioHighlightsPreloading = ({ buttons }: Props) => {
  return (
    <>
      {buttons.map(
        (button) =>
          button.video && (
            <Head key={button.video}>
              <link rel="prefetch" href={getPreloadUrl(getPublicIdFromCloudinaryUrl(button.video))} />
            </Head>
          ),
      )}
    </>
  )
}

export default PortfolioHighlightsPreloading
