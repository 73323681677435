import React from 'react'
import styled, { keyframes } from 'styled-components'
import { NextImage } from '@damen/ui'
import { HeadingTwo, Overline } from '@damen/ui/lib/cjs/components/Heading'
import { NextImageProps } from '@damen/ui/lib/cjs/types'
import { colors, mediaQueries, spacing, timing, typography } from '@damen/ui/lib/cjs/styles'
import { SustainableIcon } from '@/utils/helpers/sustainableIcon'
import { SiteNavigationBanner } from '@/queries/schema.generated'
import { NAVIGATION_BREAKPOINT_LARGE } from '../consts'

const BackgroundWrapper = styled.div`
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  left: 0;
  top: 0;
`

const StyledImage = styled(NextImage)`
  object-position: center;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Inner = styled.div`
  animation: ${fadeIn} ${timing.slow};
`

const Background = styled.div.withConfig({
  shouldForwardProp: (prop) => !['desktop', 'mobile'].includes(prop),
})<{ desktop: string; mobile: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${colors.blue};
  background-image: url('${({ mobile }) => mobile}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media ${mediaQueries.mediaQueryTablet} {
    background-image: url('${({ desktop }) => desktop}');
  }
`

const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 199px;
  color: ${colors.white};

  @media (min-width: ${NAVIGATION_BREAKPOINT_LARGE}px) {
    min-height: 207px;
  }
`

const Content = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isLoading'].includes(prop),
})<{ isLoading: boolean }>`
  position: relative;
  z-index: 2;
  max-width: 452px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacing.x4}px ${spacing.x3}px;
  transition: opacity ${timing.fast};
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};

  @media ${mediaQueries.mediaQueryTablet} {
    height: 100%;
  }
`

const ButtonWrapper = styled.div`
  margin-top: ${spacing.x2}px;
`

const Gradient = styled.div`
  position: absolute;
  display: block;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0.4;
  background-image: linear-gradient(to right, #000000 0%, rgba(102, 102, 102, 0) 100%);
`

const Title = styled(HeadingTwo)`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;

  /* stylelint-disable */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* stylelint-enable */
`

const Subtitle = styled(Overline)`
  display: flex;
  align-items: center;
  gap: ${spacing.x1}px;
  margin-bottom: ${spacing.x1}px;
  font-size: ${typography.fontSizeTextXSmall}px;
`

const PlaceholderImage =
  "data:image/svg+xml,%3csvg%20width='376'%20height='212'%20viewBox='0%200%20376%20212'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%20%3crect%20width='376'%20height='212'%20fill='%23F2F6F8'/%3e%20%3cpath%20fill-rule='evenodd'%20clip-rule='evenodd'%20d='M157.876%20107.26V107.184L143%2096.277V83.3745L157.876%2094.2813V94.3582L173.124%2083.3745L188%2094.2813L202.876%2083.3751L217.752%2094.2813V94.3582L233%2083.3745V96.277L217.752%20107.26V107.184L202.876%2096.277L188%20107.184L173.124%2096.277L157.876%20107.26ZM157.876%20128.549L143%20117.642V104.74L157.876%20115.646V115.723L173.124%20104.74L188%20115.646L202.876%20104.74L217.752%20115.646V115.723L233%20104.74V117.642L217.752%20128.626V128.549L202.876%20117.642L188%20128.549L173.124%20117.642L157.876%20128.626V128.549Z'%20fill='%23D6EBF1'/%3e%20%3c/svg%3e"

export interface Props {
  buttonComponent?: React.ComponentType
  image: NextImageProps
  loading?: boolean
  title: SiteNavigationBanner['title']
  subtitle?: SiteNavigationBanner['subtitle']
  icon: SiteNavigationBanner['icon']
}

const MenuBanner: React.FC<Props> = ({
  buttonComponent: ButtonComponent,
  image,
  loading = false,
  title,
  subtitle,
  icon,
}) => (
  <Container>
    <Content isLoading={loading}>
      <Subtitle>
        <>
          {subtitle}
          <SustainableIcon iconType={icon} height={14} />
        </>
      </Subtitle>
      <Title>{title}</Title>
      {ButtonComponent && (
        <ButtonWrapper>
          <ButtonComponent />
        </ButtonWrapper>
      )}
    </Content>
    <BackgroundWrapper>
      {loading ? (
        <Inner>
          <Background desktop={PlaceholderImage} mobile={PlaceholderImage} />
          <Gradient />
        </Inner>
      ) : (
        <Inner>
          <StyledImage {...image} src={image.src} alt={image.alt} fill style={{ objectFit: 'cover' }} />
          <Gradient />
        </Inner>
      )}
    </BackgroundWrapper>
  </Container>
)

export default MenuBanner
