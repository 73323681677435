import React, { useRef } from 'react'
import { Button, Form, Icon } from '@damen/ui'
import { Formik, Form as FormWrapper } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'

import { getClientId } from '@/lib/analytics'
import Yup from '@/components/Forms/defaultValidations'
import { PHONENUMBER_MAXLENGTH, PHONENUMBER_REGEX } from '@/components/Forms/constants'
import { getPublicEnv } from '@/utils/env'
import { FormDisclaimer, FormSection, FormFieldWrapper, FormField, FormButtonBar } from '../styles'

interface Props {
  formData: any
  isLoading: boolean
  handleOnSubmit: (values: any) => void
  handleOnError: () => void
  stickyButton?: boolean
}

const FormContainer: React.FC<React.PropsWithChildren<Props>> = ({
  formData,
  isLoading,
  handleOnSubmit,
  handleOnError,
  stickyButton = true,
}) => {
  const reCaptchaRef = useRef(null)
  const FeedbackSchema = Yup.object().shape({
    email: Yup.string().email(formData.validations.email).required(formData.errors.email),
    firstname: Yup.string().required(formData.errors.firstname).noUrl(formData.genericValidations.containsUrl),
    lastname: Yup.string().required(formData.errors.lastname).noUrl(formData.genericValidations.containsUrl),
    phone: Yup.string().required(formData.errors.phone).matches(PHONENUMBER_REGEX, formData.validations.phone),
    company: Yup.string().required(formData.errors.company).noUrl(formData.genericValidations.containsUrl),
    message: Yup.string().required(formData.errors.message).noUrl(formData.genericValidations.containsUrl),
  })

  return (
    <FormSection>
      <Formik
        initialValues={{
          email: '',
          company: '',
          firstname: '',
          lastname: '',
          phone: '',
          message: '',
          subscribeNewsletter: false,
          gRecaptchaResponse: '',
        }}
        validationSchema={FeedbackSchema}
        onSubmit={async (values) => {
          try {
            const token = await reCaptchaRef.current.executeAsync()
            const valuesToSend = { ...values, gRecaptchaResponse: token }

            handleOnSubmit({ ...valuesToSend, ...getClientId() }); return;
          } catch (error) {
            handleOnError(); return;
          }
        }}
      >
        {({ dirty, errors, touched, values, handleChange, handleSubmit }) => {
          return (
            <FormWrapper data-testid="form-feedback">
              <FormFieldWrapper>
                <FormField>
                  <Form.Input
                    error={errors.firstname || ''}
                    name="firstname"
                    placeholder={formData.placeholders.firstname}
                    touched={touched.firstname}
                    label={`${formData.labels.firstname} *`}
                    value={values.firstname}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <Form.Input
                    error={errors.lastname || ''}
                    name="lastname"
                    placeholder={formData.placeholders.lastname}
                    touched={touched.lastname}
                    label={`${formData.labels.lastname} *`}
                    value={values.lastname}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <Form.Input
                    error={errors.company || ''}
                    name="company"
                    touched={touched.company}
                    label={`${formData.labels.company} *`}
                    extraLabel={formData.extraLabel.company}
                    value={values.company}
                    placeholder={formData.placeholders.company}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <Form.Input
                    error={errors.email || ''}
                    name="email"
                    value={values.email}
                    touched={touched.email}
                    label={`${formData.labels.email} *`}
                    placeholder={formData.placeholders.email}
                    onChange={handleChange}
                    type="email"
                  />
                </FormField>
                <FormField>
                  <Form.Input
                    error={errors.phone || ''}
                    name="phone"
                    touched={touched.phone}
                    label={`${formData.labels.phone} *`}
                    extraLabel={formData.extraLabel.phone}
                    value={values.phone}
                    placeholder={formData.placeholders.phone}
                    onChange={handleChange}
                    maxLength={PHONENUMBER_MAXLENGTH}
                  />
                </FormField>
                <FormField>
                  <Form.Textarea
                    error={errors.message || ''}
                    name="message"
                    touched={touched.message}
                    label={`${formData.labels.message} *`}
                    value={values.message}
                    placeholder={formData.placeholders.message}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey={getPublicEnv('NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY')}
                    size="invisible"
                    badge="inline"
                  />
                </FormField>
              </FormFieldWrapper>
              {formData.richText?.disclaimer && <FormDisclaimer>{formData.richText?.disclaimer}</FormDisclaimer>}
              <FormButtonBar stickyButton={stickyButton}>
                <Button.Default
                  testId="button-form-submit"
                  text={formData.buttonText}
                  onClick={() => handleSubmit()}
                  icon={Icon.LeftChevron}
                  disabled={!dirty || isLoading}
                />
              </FormButtonBar>
            </FormWrapper>
          )
        }}
      </Formik>
    </FormSection>
  )
}

export default FormContainer
