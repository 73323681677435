import React from 'react'
import { useRouter } from 'next/router'
import { Header, Tag, theme } from '@damen/ui'
import styled from 'styled-components'

import DeepDive from '@/components/DeepDive'
import Link from '@/components/Link'

import { useModal } from '@/hooks'
import { HeaderProps } from './types'
import { getSustainableMarkWithPopover } from '@/utils/helpers/sustainableIcon'
import { QUOTE_QUERYSTRING } from '@/components/Forms/constants'
import { HEADER_ANIMATION_DELAY } from '@/constants/animations'

interface Content {
  id: string
  header: HeaderProps
}

interface Props {
  content: Content
}

const SecondaryHeader: React.FC<Props> = ({ content }) => {
  const { header } = content
  const { modalIsOpen, onCloseModal, onOpenModal } = useModal('deepdive')
  const router = useRouter()
  let link = null

  if (header.linkText && (header.link?.cachedUrl || header.link?.url || header.deepDive.length > 0)) {
    const currentPage = router.asPath.replace(QUOTE_QUERYSTRING, '')
    const mappedLink =
      header.link?.url === QUOTE_QUERYSTRING
        ? { ...header.link, url: `${currentPage}/${header.link?.url}` }
        : header.link

    link = {
      action: header.deepDive && header.deepDive.length > 0 ? () => onOpenModal() : null,
      link: mappedLink,
      text: header.linkText,
    }
  }

  const labelWithIcon = () => {
    return (
      <>
        {header.label}
        {getSustainableMarkWithPopover(header.sustainableMark)}
      </>
    )
  }

  const headerLabel = () => {
    const { sustainableMark, tag, label } = header
    if (sustainableMark && tag) {
      return (
        <StyledTagWithMark>
          {getSustainableMarkWithPopover(sustainableMark)}{' '}
          <TagWrapper>
            <Tag.Default {...tag} />
          </TagWrapper>
        </StyledTagWithMark>
      )
    }
    if (tag) {
      return <Tag.Default {...tag} />
    }
    if (sustainableMark) {
      return labelWithIcon()
    }

    return label
  }

  // TODO: Breadcrumb breaks the page on mobile view (as it is only rendered on mobile)
  // Disabled for now to resolve the issue. Mobile has less prio as only a small percentage of users is viewing the site on mobile
  // Do we still need this as we have a breadcrumb component on the page?
  if (header.__typename === 'HeaderSecondaryNoImage') {
    return (
      <Container withBreadcrumb={!!header.breadcrumb}>
        <Header.Secondary
          animationDelay={HEADER_ANIMATION_DELAY}
          // breadcrumb={header.breadcrumb ? header.breadcrumb : null}
          buttonComponent={
            link &&
            (() => (
              <Link.VNext
                type="button"
                color={header.buttonType}
                link={link.link}
                text={header.linkText}
                onClick={void link.action}
              />
            ))
          }
          buttonType={header.buttonType}
          label={header.sustainableMark ? labelWithIcon() : header.label}
          overlay={header.overlay}
          title={header.title}
          text={header.text}
        />
      </Container>
    )
  }

  return (
    <DefaultContainer>
      {header.__typename !== 'HeaderSplit' ? (
        <Header.Secondary
          animationDelay={HEADER_ANIMATION_DELAY}
          // breadcrumb={header.breadcrumb ? header.breadcrumb : null}
          buttonComponent={
            link &&
            (() => (
              <Link.VNext
                color={header.buttonType}
                link={link.link}
                text={header.linkText}
                type="button"
                onClick={void link.action}
                testId="header.button"
              />
            ))
          }
          image={{
            alt: header.img.alt,
            src: header.img.filename,
          }}
          buttonType={header.buttonType}
          label={headerLabel()}
          overlay={header.overlay}
          overlayColor={header.overlayColor}
          title={header.title}
          text={header.text}
        />
      ) : (
        <Header.Split
          // breadcrumb={header.breadcrumb ? header.breadcrumb : null}
          bowWave={header.bowWave}
          buttonComponent={
            link &&
            (() => (
              <Link.VNext
                type="button"
                color={header.buttonType}
                link={link.link}
                text={header.linkText}
                onClick={void link.action}
              />
            ))
          }
          image={{
            alt: header.img.alt,
            src: header.img.filename,
          }}
          label={header.sustainableMark ? labelWithIcon() : header.label}
          overlay={header.overlay}
          title={header.title}
        />
      )}
      {header.deepDive?.length > 0 && (
        <DeepDive isOpen={modalIsOpen} onClickHandler={onCloseModal} content={header.deepDive} />
      )}
    </DefaultContainer>
  )
}

const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => !['withBreadcrumb'].includes(prop),
})<{ withBreadcrumb: boolean }>`
  @media ${theme.legacyMediaQueries.mdMax} {
    padding-top: 40px;
  }

  ${({ withBreadcrumb }) =>
    !withBreadcrumb &&
    `
    @media ${theme.legacyMediaQueries.md} {
      padding-top: 80px;
    }

    @media ${theme.legacyMediaQueries.lg} {
      padding-top: 120px;
    }
  `}
`

const DefaultContainer = styled.div`
  z-index: 1;
`

const TagWrapper = styled.span`
  margin-left: ${theme.layout.spacingM};
`

const StyledTagWithMark = styled.span`
  display: flex;
  align-items: center;

  svg {
    margin-top: -7px;
  }
`

export default SecondaryHeader
