const setBodyScrollLock = (bodyScrollLock: boolean) => {
  const mainElement = document.getElementsByTagName('main')[0]
  if (bodyScrollLock) {
    document.body.classList.add('ReactModal__Body--open')
    mainElement.classList.add('ReactModal__Body--open')
  } else {
    document.body.classList.remove('ReactModal__Body--open')
    mainElement.classList.remove('ReactModal__Body--open')
  }
}

export default setBodyScrollLock
