import React, { useRef, useState } from 'react'
import { Button, Form, Icon } from '@damen/ui'
import { Formik, Form as FormWrapper, ErrorMessage } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'

import { useIsoCountries } from '@/hooks'
import { getClientId } from '@/lib/analytics'
import Yup from '@/components/Forms/defaultValidations'
import { MESSAGE_MAX_LENGTH, PHONENUMBER_MAXLENGTH, PHONENUMBER_REGEX } from '@/components/Forms/constants'
import { getPublicEnv } from '@/utils/env'
import {
  FormDisclaimer,
  FormSection,
  FormFieldWrapper,
  FormField,
  FormButtonBar,
  FormFieldError,
  InterestedInTextWithLinkAction,
  StyledContactLink,
} from '../styles'
import { useCurrentGoogleClickId } from '@/hooks/googleClickId'

interface Props {
  formData: any
  isLoading: boolean
  handleOnSubmit: (values: any) => void
  handleOnError: () => void
  stickyButton?: boolean
}

const shouldRenderCountryOfOperations = (subject: string) => subject.toLowerCase() === 'new vessels'

const getContactSchema = (data: any) => {
  switch (data.formType) {
    case 'simple':
      return Yup.object().shape({
        email: Yup.string().email(data.validations.email).required(data.errors.email),
        firstname: Yup.string().required(data.errors.firstname).noUrl(data.genericValidations.containsUrl),
        lastname: Yup.string().required(data.errors.lastname).noUrl(data.genericValidations.containsUrl),
        message: Yup.string().noUrl(data.genericValidations.containsUrl).max(MESSAGE_MAX_LENGTH),
      })

    case 'default':
    default:
      return Yup.object().shape({
        email: Yup.string().email(data.validations.email).required(data.errors.email),
        firstname: Yup.string().required(data.errors.firstname).noUrl(data.genericValidations.containsUrl),
        lastname: Yup.string().required(data.errors.lastname).noUrl(data.genericValidations.containsUrl),
        phone: Yup.string().matches(PHONENUMBER_REGEX, data.validations.phone),
        company: Yup.string().noUrl(data.genericValidations.containsUrl),
        message: Yup.string().noUrl(data.genericValidations.containsUrl).max(MESSAGE_MAX_LENGTH),
        subject: Yup.string().required(data.errors.subject),
        country: Yup.string().required(data.errors.country),
      })
  }
}

const FormContainer: React.FC<React.PropsWithChildren<Props>> = ({
  formData,
  isLoading,
  handleOnSubmit,
  handleOnError,
  stickyButton = true,
}) => {
  const { countryList } = useIsoCountries()
  const reCaptchaRef = useRef(null)
  const [interestActiveContent, setInterestActiveContent] = useState(null)
  const ContactSchema = getContactSchema(formData)
  const { googleClickId } = useCurrentGoogleClickId()

  const interestedInShouldAutoselected = formData.options.length === 1
  const interestedIninitialValue: string = interestedInShouldAutoselected ? formData.options[0]?.value : ''

  return (
    <FormSection>
      <Formik
        initialValues={{
          country: '',
          countryOfOperation: null,
          email: '',
          company: '',
          firstname: '',
          lastname: '',
          phone: '',
          subject: interestedInShouldAutoselected ? interestedIninitialValue : '',
          interestedIn: interestedInShouldAutoselected ? interestedIninitialValue : '',
          message: '',
          subscribeNewsletter: false,
          gRecaptchaResponse: '',
        }}
        validationSchema={ContactSchema}
        onSubmit={async (values, actions) => {
          try {
            const token = await reCaptchaRef.current.executeAsync()

            if (!values.countryOfOperation && shouldRenderCountryOfOperations(values.subject)) {
              actions.setErrors({ countryOfOperation: formData.errors.countryOfOperation }); return;
            }

            const valuesToSend = { ...values, gRecaptchaResponse: token, googleClickId }

            handleOnSubmit({ ...valuesToSend, ...getClientId() }); return;
          } catch (error) {
            handleOnError(); return;
          }
        }}
      >
        {({ dirty, errors, touched, values, handleChange, handleSubmit, setFieldValue }) => {
          return (
            <FormWrapper data-testid="form-contact">
              <FormFieldWrapper>
                {!interestedInShouldAutoselected && formData.formType !== 'simple' && (
                  <FormField data-testid="contact-form.subject">
                    <Form.Select
                      label={`${formData.labels.subject} *`}
                      options={formData.options}
                      initialValue={values.subject}
                      onSelect={(option) => {
                        void setFieldValue('interestedIn', option)
                        if (!shouldRenderCountryOfOperations(option.toString())) {
                          void setFieldValue('countryOfOperation', null)
                        }
                        setInterestActiveContent(
                          formData.optionsActiveContent.find((item: any) => item.id === values.subject),
                        )

                        return void setFieldValue('subject', option)
                      }}
                    />
                    {errors.subject && <FormFieldError>{errors.subject}</FormFieldError>}
                    {interestActiveContent && (
                      <InterestedInTextWithLinkAction>
                        {interestActiveContent.activeContent.text}{' '}
                        <StyledContactLink type="raw" link={`tel:${interestActiveContent.activeContent.contact.phone}`}>
                          {interestActiveContent.activeContent.contact.phone}
                        </StyledContactLink>
                      </InterestedInTextWithLinkAction>
                    )}
                  </FormField>
                )}
                <FormField data-testid="contact-form.firstname">
                  <Form.Input
                    error={errors.firstname || ''}
                    name="firstname"
                    placeholder={formData.placeholders.firstname}
                    touched={touched.firstname}
                    label={`${formData.labels.firstname} *`}
                    value={values.firstname}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField data-testid="contact-form.lastname">
                  <Form.Input
                    error={errors.lastname || ''}
                    name="lastname"
                    placeholder={formData.placeholders.lastname}
                    touched={touched.lastname}
                    label={`${formData.labels.lastname} *`}
                    value={values.lastname}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField data-testid="contact-form.email">
                  <Form.Input
                    error={errors.email || ''}
                    name="email"
                    value={values.email}
                    touched={touched.email}
                    label={`${formData.labels.email} *`}
                    placeholder={formData.placeholders.email}
                    onChange={handleChange}
                    type="email"
                  />
                </FormField>
                {formData.formType !== 'simple' && (
                  <FormField data-testid="contact-form.country">
                    <Form.Select
                      label={`${formData.labels.countries} *`}
                      options={countryList}
                      initialValue={values.country}
                      onSelect={(option) => void setFieldValue('country', option)}
                    />
                    <ErrorMessage name="country" render={(msg) => <FormFieldError>{msg}</FormFieldError>} />
                  </FormField>
                )}
                {shouldRenderCountryOfOperations(values.subject) && (
                  <FormField data-testid="contact-form.countryOfOperation">
                    <Form.Select
                      label={`${formData.labels.countryOfOperation} *`}
                      options={countryList}
                      initialValue={values.countryOfOperation}
                      onSelect={(option) => void setFieldValue('countryOfOperation', option)}
                    />
                    <ErrorMessage name="countryOfOperation" render={(msg) => <FormFieldError>{msg}</FormFieldError>} />
                  </FormField>
                )}
                {formData.formType !== 'simple' && (
                  <FormField>
                    <Form.Input
                      error={errors.phone || ''}
                      name="phone"
                      touched={touched.phone}
                      label={formData.labels.phone}
                      extraLabel={formData.extraLabel.phone}
                      value={values.phone}
                      placeholder={formData.placeholders.phone}
                      onChange={handleChange}
                      maxLength={PHONENUMBER_MAXLENGTH}
                    />
                  </FormField>
                )}
                {formData.formType !== 'simple' && (
                  <FormField data-testid="contact-form.company">
                    <Form.Input
                      error={errors.company || ''}
                      name="company"
                      touched={touched.company}
                      label={formData.labels.company}
                      extraLabel={formData.extraLabel.company}
                      value={values.company}
                      placeholder={formData.placeholders.company}
                      onChange={handleChange}
                    />
                  </FormField>
                )}
                <FormField data-testid="contact-form.message">
                  <Form.Textarea
                    error={errors.message || ''}
                    name="message"
                    touched={touched.message}
                    label={formData.labels.message}
                    value={values.message}
                    placeholder={formData.placeholders.message}
                    onChange={handleChange}
                    maxLength={MESSAGE_MAX_LENGTH}
                  />
                </FormField>
                {formData.labels.subscribe && formData.formType !== 'simple' && (
                  <FormField data-testid="contact-form.subscribe">
                    <Form.Checkbox
                      background="blue"
                      error={errors.subscribeNewsletter}
                      touched={touched.subscribeNewsletter}
                      name="subscribeNewsletter"
                      label={formData.labels.subscribe}
                      onChange={handleChange}
                    />
                  </FormField>
                )}
                <FormField>
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey={getPublicEnv('NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY')}
                    size="invisible"
                    badge="inline"
                  />
                </FormField>
              </FormFieldWrapper>
              {formData.richText?.disclaimer && <FormDisclaimer>{formData.richText?.disclaimer}</FormDisclaimer>}
              <FormButtonBar stickyButton={stickyButton} data-testid="contact-form.button-submit">
                <Button.Default
                  text={formData.buttonText}
                  onClick={() => handleSubmit()}
                  icon={Icon.LeftChevron}
                  disabled={!dirty || isLoading}
                />
              </FormButtonBar>
            </FormWrapper>
          )
        }}
      </Formik>
    </FormSection>
  )
}

export default FormContainer
