import * as Yup from 'yup'
import { AnyObject, Flags, Maybe, Schema } from 'yup'

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TDefault = undefined,
    TFlags extends Flags = '',
  > extends Schema<TType, TContext, TDefault, TFlags> {
    noUrl(message: string): StringSchema<TType, TContext>
  }
}

export const LINK_REGEX = /(http:|https:|www\.)(.+)/

const doesStringContainUrl = (string: string) => LINK_REGEX.test(string)

function noUrl(message: string) {
  // https://github.com/jquense/yup?tab=readme-ov-file#addmethodschematype-schema-name-string-method--schema-void
  // @ts-expect-error This is taken from the Yup docs
  return this.test({
    message,
    name: 'noUrl',
    test(value: string) {
      if (value !== undefined) {
        return !doesStringContainUrl(value)
      }
      return true
    },
  })
}
Yup.addMethod(Yup.string, 'noUrl', noUrl)

export default Yup
