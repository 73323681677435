import React from 'react'
import { Icon, theme } from '@damen/ui'
import LinkVNext from '@/components/Link'
import {
  Address,
  AddressContent,
  Detail,
  Footer,
  Inner,
  Label,
  Link,
  StyledObfuscate,
  Subtitle,
  Title,
  TitleWrapper,
  Value,
} from '../../contactFormDetailStyles'

interface Props {
  departmentContactDetails: any
  showBowWave?: boolean
}

const Details: React.FC<React.PropsWithChildren<Props>> = ({ departmentContactDetails, showBowWave = true }) => {
  const { title } = departmentContactDetails

  return (
    <Inner>
      {title && (
        <TitleWrapper>
          <Title>{title}</Title>
        </TitleWrapper>
      )}
      {departmentContactDetails && (
        <>
          {departmentContactDetails.phonenumber.label && departmentContactDetails.phonenumber.value && (
            <Detail key={departmentContactDetails.phonenumber.label}>
              <Label>{departmentContactDetails.phonenumber.label}</Label>
              <Value>
                <Link href={`tel:${departmentContactDetails.phonenumber.value}`} rel="noreferrer">
                  {departmentContactDetails.phonenumber.value}
                </Link>
              </Value>
            </Detail>
          )}
          {departmentContactDetails.email.label && departmentContactDetails.email.value && (
            <Detail key={departmentContactDetails.email.label}>
              <Label>{departmentContactDetails.email.label}</Label>
              <Value>
                <StyledObfuscate email={departmentContactDetails.email.value} />
              </Value>
            </Detail>
          )}
          <AddressContent>
            {departmentContactDetails.address && <Subtitle>{departmentContactDetails.address.title}</Subtitle>}
            <Address>
              <Value key={departmentContactDetails.address.street}>{departmentContactDetails.address.street}</Value>
              <Value key={departmentContactDetails.address.city}>{departmentContactDetails.address.city}</Value>
              <Value key={departmentContactDetails.address.country}>{departmentContactDetails.address.country}</Value>
            </Address>
            {departmentContactDetails.link?.cachedUrl && departmentContactDetails.linkText ? (
              <LinkVNext.VNext
                link={departmentContactDetails.link}
                text={departmentContactDetails.linkText}
                type="contentlink"
                size="small"
              />
            ) : null}
          </AddressContent>
        </>
      )}
      {showBowWave && (
        <Footer>
          <Icon.BowWaveSmall fill={theme.colors.orange} width={99} height={50} />
        </Footer>
      )}
    </Inner>
  )
}

export default Details
