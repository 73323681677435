import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Icon } from '@damen/ui'
import { Formik, Form as FormWrapper } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'

import { getClientId } from '@/lib/analytics'
import Yup from '@/components/Forms/defaultValidations'
import { getPublicEnv } from '@/utils/env'
import { FormDisclaimer, FormSection, FormFieldWrapper, FormField, FormButtonBar } from '../styles'

interface Props {
  formData: any
  isLoading: boolean
  handleOnSubmit: (values: any) => void
  handleOnError: () => void
  stickyButton?: boolean
}

const FormContainer: React.FC<React.PropsWithChildren<Props>> = ({
  formData,
  isLoading,
  handleOnSubmit,
  handleOnError,
  stickyButton = true,
}) => {
  const reCaptchaRef = useRef(null)
  const [renderCaptcha, setRenderCaptcha] = useState(false)
  const ContactSchema = Yup.object().shape({
    email: Yup.string().email(formData.validations.email).required(formData.errors.email),
    firstname: Yup.string().required(formData.errors.firstname).noUrl(formData.genericValidations.containsUrl),
    lastname: Yup.string().required(formData.errors.lastname).noUrl(formData.genericValidations.containsUrl),
    privacyStatementAccepted: Yup.boolean().oneOf([true], formData.errors.privacy),
  })

  useEffect(() => {
    setRenderCaptcha(true)
  }, [])

  return (
    <FormSection>
      <Formik
        initialValues={{
          email: '',
          firstname: '',
          lastname: '',
          subscribeNewsletter: false,
          privacyStatementAccepted: false,
          gRecaptchaResponse: '',
        }}
        validationSchema={ContactSchema}
        onSubmit={async (values) => {
          try {
            const token = await reCaptchaRef.current.executeAsync()
            const valuesToSend = { ...values, gRecaptchaResponse: token }

            handleOnSubmit({ ...valuesToSend, ...getClientId() }); return;
          } catch (error) {
            handleOnError(); return;
          }
        }}
      >
        {({ dirty, errors, touched, values, handleChange, handleSubmit }) => {
          return (
            <FormWrapper data-testid="form-campaign-small">
              <FormFieldWrapper>
                <FormField>
                  <Form.Input
                    error={errors.firstname || ''}
                    name="firstname"
                    placeholder={formData.placeholders.firstname}
                    touched={touched.firstname}
                    label={`${formData.labels.firstname} *`}
                    value={values.firstname}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <Form.Input
                    error={errors.lastname || ''}
                    name="lastname"
                    placeholder={formData.placeholders.lastname}
                    touched={touched.lastname}
                    label={`${formData.labels.lastname} *`}
                    value={values.lastname}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <Form.Input
                    error={errors.email || ''}
                    name="email"
                    value={values.email}
                    touched={touched.email}
                    label={`${formData.labels.email} *`}
                    placeholder={formData.placeholders.email}
                    onChange={handleChange}
                    type="email"
                  />
                </FormField>
                {formData.labels.subscribe && (
                  <FormField>
                    <Form.Checkbox
                      background="blue"
                      error={errors.subscribeNewsletter}
                      touched={touched.subscribeNewsletter}
                      name="subscribeNewsletter"
                      label={formData.labels.subscribe}
                      onChange={handleChange}
                    />
                  </FormField>
                )}
                <FormField>
                  <Form.Checkbox
                    background="blue"
                    error={errors.privacyStatementAccepted}
                    touched={touched.privacyStatementAccepted}
                    name="privacyStatementAccepted"
                    label={formData.labels.privacy}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  {renderCaptcha && (
                    <ReCAPTCHA
                      ref={reCaptchaRef}
                      sitekey={getPublicEnv('NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY')}
                      size="invisible"
                      badge="inline"
                    />
                  )}
                </FormField>
              </FormFieldWrapper>
              {formData.richText?.disclaimer && <FormDisclaimer>{formData.richText?.disclaimer}</FormDisclaimer>}
              <FormButtonBar stickyButton={stickyButton}>
                <Button.Default
                  testId="button-form-submit"
                  text={formData.buttonText}
                  onClick={() => handleSubmit()}
                  icon={Icon.LeftChevron}
                  disabled={!dirty || isLoading}
                />
              </FormButtonBar>
            </FormWrapper>
          )
        }}
      </Formik>
    </FormSection>
  )
}

export default FormContainer
