import React from 'react'
import { Icon, theme } from '@damen/ui'
import styled from 'styled-components'
import { useReactiveVar } from '@apollo/client'
import { showFamilyNav } from '@/lib/apollo/useApollo'

import Link from '@/components/Link'

import OverflowMenu from '@/components/UI/SiteNavigation/components/OverflowMenu'
import SiteNavigationDesktop from './SiteNavigationDesktop'
import { LogoWrapper, MenuItem } from './styles'
import SiteNavigationBarLogo from './components/Logo'
import { SiteNavigationItem, SiteNavigationNeedHelp, SiteNavigationOverflowItem } from '@/queries/schema.generated'
import { NAVIGATION_BREAKPOINT_LARGE } from './consts'

const MenuHoverStyle = `
  g g g {
    rect:first-child {
      opacity: 0;
    }

    rect:nth-child(3) {
      transform: rotate(45deg);
    }

    rect:nth-child(2) {
      width: 16px;
      transform: rotate(-45deg);
    }
  }
`

const MenuIcon = styled.button.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen?: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'inline-block')};
  justify-self: flex-end;
  left: 16px;
  top: 16px;
  padding: 0;
  background: transparent;
  border: none;

  svg {
    display: block !important;
  }

  g g g {
    rect {
      transition:
        opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1),
        transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      will-change: opacity, width, transform;

      &:nth-child(3) {
        transform-origin: 5% 28%;
      }

      &:nth-child(2) {
        transform-origin: 50% 50%;
      }
    }
  }

  ${({ isOpen }) => isOpen && MenuHoverStyle}

  &:focus {
    outline: ${theme.accessibility.outline};
    outline-offset: 8px;
  }

  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${NAVIGATION_BREAKPOINT_LARGE}px) {
    display: none;
  }
`

const MobileSearchItem = styled(Link.VNext)`
  @media (min-width: ${NAVIGATION_BREAKPOINT_LARGE}px) {
    display: none;
  }
`

const Container = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['isMenuOpen', 'isOpen', 'show', 'isAtTop', 'variant', 'familyNavIsShown', 'closeMenuTitle'].includes(prop),
})<{
  isMenuOpen?: boolean
  show?: boolean
  isAtTop?: boolean
  variant?: 'light' | 'dark'
  familyNavIsShown: boolean
}>`
  position: fixed;
  width: 100%;
  z-index: 11;
  padding: ${theme.spacing.x2}px;
  background: ${({ isMenuOpen }) => (isMenuOpen ? '#fff' : 'transparent')};
  border-bottom: ${({ isMenuOpen }) => (isMenuOpen ? `2px solid ${theme.colors.blueIce}` : '2px solid transparent')};
  color: ${({ variant, isMenuOpen }) =>
    variant === 'dark' || isMenuOpen ? theme.colors.marineBlack : theme.colors.white};
  transform: translateY(-100%);
  transition:
    transform 200ms ease-out,
    background 600ms ease-in-out,
    color 600ms ease-in-out;

  ${({ familyNavIsShown, isMenuOpen }) =>
    familyNavIsShown && !isMenuOpen
      ? `
        opacity: 0 !important;
        visibility: hidden !important;
      `
      : `
        opacity: 1;
        visibility: visible;
      `}

  ${({ show }) =>
    show &&
    `
        transform: translateY(0%);
        // Slow down when appearing
        transition-duration: 600ms;
    `}

  ${({ isAtTop }) =>
    !isAtTop &&
    `
        background: ${theme.colors.white};
        color: ${theme.colors.marineBlack};

        ${LogoWrapper} {
          * {
            fill: ${theme.colors.blue};
          }
        }

    `}

  ${({ variant }) =>
    variant === 'dark' &&
    `
        color: ${theme.colors.marineBlack};

        ${MenuItem} {
          color: ${theme.colors.marineBlack};
        }
    `}

  @media ${theme.mediaQueries.mediaQueryTablet} {
    padding: 0 ${theme.spacing.x4}px;
  }

  @media (min-width: ${NAVIGATION_BREAKPOINT_LARGE}px) {
    padding: 0 ${theme.spacing.x4}px;
    // Make use of the MenuOverlay to close the menu
    pointer-events: none;
  }
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 200px 3fr;
  align-items: center;
  width: 100%;

  @media ${theme.mediaQueries.mediaQueryTablet} {
    padding: ${theme.spacing.x2}px 0;
  }

  @media (min-width: ${NAVIGATION_BREAKPOINT_LARGE}px) {
    margin: 0 auto;
    max-width: 1040px;
    padding: ${theme.spacing.x3}px 0;
    // Prevent closing the menu when clicking inside the content area
    pointer-events: all;
  }

  @media (${theme.mediaQueries.mediaQueryDesktopLarge}) {
    max-width: ${theme.layout.gridWidthInner};
  }
`

const StyledMobileBackButton = styled(Link.VNext)`
  padding: 0;

  &:hover {
    background-color: transparent;
  }

  @media (min-width: ${NAVIGATION_BREAKPOINT_LARGE}px) {
    display: none;
  }
`

const MobileSearchPlaceholder = styled.span`
  @media (min-width: ${NAVIGATION_BREAKPOINT_LARGE}px) {
    display: none;
  }
`

interface Props {
  currentPage: string
  isMenuOpen?: boolean
  items?: SiteNavigationItem[]
  overflowItems?: SiteNavigationOverflowItem[]
  needHelp?: SiteNavigationNeedHelp
  variant?: 'light' | 'dark'
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  show: boolean
  isAtTop: boolean
  toggleMobileMenu: () => void
  activeItem: string
  setActiveItem: React.Dispatch<React.SetStateAction<string>>
  navigationBarRef?: React.MutableRefObject<HTMLDivElement>
}

const SiteNavigationBar: React.FC<React.PropsWithChildren<Props>> = ({
  navigationBarRef,
  currentPage,
  activeItem,
  isMenuOpen,
  items,
  overflowItems,
  needHelp,
  setActiveItem,
  setIsMenuOpen,
  toggleMobileMenu,
  variant = 'light',
  ...props
}) => {
  const familyNavIsShown = useReactiveVar<boolean>(showFamilyNav)
  const searchItem = overflowItems?.find((item) => item.iconType === 'Search')
  const isSecondLevelActive = activeItem.split('/').length <= 1
  const showMobileSearchIcon = !isMenuOpen || (searchItem && isSecondLevelActive)

  return (
    <Container
      isMenuOpen={isMenuOpen}
      variant={variant}
      familyNavIsShown={familyNavIsShown}
      data-testid="site-navigation-header-container"
      {...props}
    >
      <ContentWrapper ref={navigationBarRef}>
        {/* Mobile search */}
        {/* Placeholder is needed to keep the SiteNavigationBar items aligned */}
        {!searchItem && isSecondLevelActive && <MobileSearchPlaceholder />}
        {showMobileSearchIcon && searchItem && (
          <MobileSearchItem link={searchItem.link} type="link" data-testid="navigationBar.mobile.item.search">
            <Icon.Search
              fill={variant === 'dark' || isMenuOpen || !props.isAtTop ? theme.colors.blue : theme.colors.white}
            />
          </MobileSearchItem>
        )}
        {/* Mobile Back Button */}
        {isMenuOpen && activeItem.split('/').length >= 2 && (
          <StyledMobileBackButton
            type="button"
            color="white"
            icon={Icon.RightChevron}
            iconSize={18}
            data-testid="navigationBar.mobile.item.back"
            onClick={() => {
              if (activeItem.split('/').length === 2) {
                setActiveItem('')
              } else {
                setActiveItem(activeItem.split('/').slice(0, -1).join('/'))
              }
            }}
          />
        )}
        {/* Desktop Main Navigation */}
        <SiteNavigationDesktop
          items={items}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          isAtTop={props.isAtTop}
          variant={variant}
          needHelp={needHelp}
          currentPage={currentPage}
        />
        {/* Site Logo */}
        <SiteNavigationBarLogo isMenuOpen={isMenuOpen} currentPage={currentPage} variant={variant} />
        {/* Overflow menu */}
        <OverflowMenu
          overflowMenu={overflowItems}
          currentPage={currentPage}
          variant={variant}
          isMenuOpen={isMenuOpen}
          isAtTop={props.isAtTop}
        />
        {/* Hamburger icon */}
        <MenuIcon onClick={() => toggleMobileMenu()} isOpen={isMenuOpen}>
          {isMenuOpen ? (
            <Icon.Close width="18" height="17" fill={theme.colors.blue} />
          ) : (
            <Icon.Menu
              fill={variant === 'dark' || isMenuOpen || !props.isAtTop ? theme.colors.blue : theme.colors.white}
              height={16}
              width={20}
            />
          )}
        </MenuIcon>
      </ContentWrapper>
    </Container>
  )
}

export default SiteNavigationBar
