import React, { useMemo, useRef } from 'react'
import { Button, Form, Icon } from '@damen/ui'
import { Formik, Form as FormWrapper, ErrorMessage } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import * as Yup from 'yup'
import { Product } from '@/queries/schema.generated'
import { useIsoCountries } from '@/hooks'
import { getClientId } from '@/lib/analytics'
import { MESSAGE_MAX_LENGTH, PHONENUMBER_MAXLENGTH, PHONENUMBER_REGEX } from '@/components/Forms/constants'
import { getPublicEnv } from '@/utils/env'
import { FormDisclaimer, FormSection, FormFieldWrapper, FormField, FormButtonBar, FormFieldError } from '../styles'
import { useCurrentGoogleClickId } from '@/hooks/googleClickId'

interface Props {
  formData: any
  isLoading: boolean
  product?: Product
  handleOnSubmit: (values: any) => void
  handleOnError: () => void
  stickyButton?: boolean
}

const FormContainer: React.FC<React.PropsWithChildren<Props>> = ({
  formData,
  isLoading,
  product,
  handleOnSubmit,
  handleOnError,
  stickyButton = true,
}) => {
  const reCaptchaRef = useRef(null)
  const { countryList } = useIsoCountries()
  const THIS_VESSEL_VALUE = 'vessel'
  const SOMETHING_ELSE_VALUE = 'something else'

  const QuoteSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email(formData.validations.email).required(formData.errors.email),
        firstname: Yup.string().noUrl(formData.genericValidations.containsUrl),
        message: Yup.string().noUrl(formData.genericValidations.containsUrl).max(MESSAGE_MAX_LENGTH),
        lastname: Yup.string().required(formData.errors.lastname).noUrl(formData.genericValidations.containsUrl),
        phone: Yup.string().matches(PHONENUMBER_REGEX, formData.validations.phone),
        interestedIn: Yup.string().required(formData.errors.interestedIn),
        country: Yup.string().required(formData.errors.country),
      }),
    [formData],
  )
  const { googleClickId } = useCurrentGoogleClickId()

  const interestedInShouldAutoselected = formData.options.length === 1
  const interestedIninitialValue: string = interestedInShouldAutoselected ? formData.options[0]?.value : ''

  return (
    <FormSection>
      <Formik
        initialValues={{
          country: '',
          countryOfOperation: null,
          email: '',
          firstname: '',
          lastname: '',
          phone: '',
          interestedIn: interestedInShouldAutoselected ? interestedIninitialValue : '',
          message: '',
          range: product?.range,
          family: product?.family,
          productName: product?.salesforceName,
          subscribeNewsletter: false,
          gRecaptchaResponse: '',
        }}
        validationSchema={QuoteSchema}
        onSubmit={async (values, actions) => {
          try {
            const token = await reCaptchaRef.current.executeAsync()

            if (!values.countryOfOperation && values.interestedIn === THIS_VESSEL_VALUE) {
              actions.setErrors({ countryOfOperation: formData.errors.countryOfOperation }); return;
            }

            const subject = values.interestedIn === THIS_VESSEL_VALUE ? product.salesforceName : values.interestedIn

            handleOnSubmit({
              ...values,
              subject,
              gRecaptchaResponse: token,
              ...getClientId(),
              googleClickId,
            }); return;
          } catch (error) {
            handleOnError(); return;
          }
        }}
      >
        {({ dirty, errors, touched, values, handleChange, handleSubmit, setFieldValue }) => {
          return (
            <FormWrapper data-testid="quote-form">
              <FormFieldWrapper>
                {!interestedInShouldAutoselected && (
                  <FormField data-testid="quote-form.subject">
                    <Form.Select
                      label={`${formData.labels.subject} *`}
                      options={formData.options}
                      initialValue={values.interestedIn}
                      onSelect={(option) => {
                        if (option === SOMETHING_ELSE_VALUE) {
                          void setFieldValue('countryOfOperation', null)
                        }

                        return void setFieldValue('interestedIn', option)
                      }}
                    />
                    {errors.interestedIn && <FormFieldError>{errors.interestedIn}</FormFieldError>}
                  </FormField>
                )}
                <FormField data-testid="quote-form.firstname">
                  <Form.Input
                    error={errors.firstname || ''}
                    name="firstname"
                    placeholder={formData.placeholders.firstname}
                    touched={touched.firstname}
                    label={formData.labels.firstname}
                    value={values.firstname}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField data-testid="quote-form.lastname">
                  <Form.Input
                    error={errors.lastname || ''}
                    name="lastname"
                    placeholder={formData.placeholders.lastname}
                    touched={touched.lastname}
                    label={`${formData.labels.lastname} *`}
                    value={values.lastname}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField data-testid="quote-form.email">
                  <Form.Input
                    error={errors.email || ''}
                    name="email"
                    value={values.email}
                    touched={touched.email}
                    label={`${formData.labels.email} *`}
                    placeholder={formData.placeholders.email}
                    onChange={handleChange}
                    type="email"
                  />
                </FormField>
                <FormField data-testid="quote-form.country">
                  <Form.Select
                    label={`${formData.labels.countries} *`}
                    options={countryList}
                    initialValue={values.country}
                    onSelect={(option) => void setFieldValue('country', option)}
                  />
                  <ErrorMessage name="country" render={(msg) => <FormFieldError>{msg}</FormFieldError>} />
                </FormField>
                {(values.interestedIn === THIS_VESSEL_VALUE ||
                  (interestedInShouldAutoselected && interestedIninitialValue === THIS_VESSEL_VALUE)) && (
                  <FormField data-testid="quote-form.country-of-operation">
                    <Form.Select
                      label={`${formData.labels.countryOfOperation} *`}
                      options={countryList}
                      initialValue={values.countryOfOperation}
                      onSelect={(option) => void setFieldValue('countryOfOperation', option)}
                    />
                    <ErrorMessage name="countryOfOperation" render={(msg) => <FormFieldError>{msg}</FormFieldError>} />
                  </FormField>
                )}
                <FormField>
                  <Form.Input
                    error={errors.phone || ''}
                    name="phone"
                    touched={touched.phone}
                    label={formData.labels.phone}
                    extraLabel={formData.extraLabel.phone}
                    value={values.phone}
                    placeholder={formData.placeholders.phone}
                    onChange={handleChange}
                    maxLength={PHONENUMBER_MAXLENGTH}
                  />
                </FormField>
                <FormField data-testid="quote-form.message">
                  <Form.Textarea
                    error={errors.message || ''}
                    name="message"
                    placeholder={formData.placeholders.textArea}
                    touched={touched.message}
                    label={formData.labels.textArea}
                    value={values.message}
                    onChange={handleChange}
                    maxLength={MESSAGE_MAX_LENGTH}
                  />
                </FormField>
                <FormField data-testid="quote-form.subscribe">
                  <Form.Checkbox
                    background="blue"
                    error={errors.subscribeNewsletter}
                    touched={touched.subscribeNewsletter}
                    name="subscribeNewsletter"
                    label={formData.labels.subscribe}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey={getPublicEnv('NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY')}
                    size="invisible"
                    badge="inline"
                  />
                </FormField>
              </FormFieldWrapper>
              {formData.richText?.disclaimer && <FormDisclaimer>{formData.richText?.disclaimer}</FormDisclaimer>}
              <FormButtonBar stickyButton={stickyButton} data-testid="quote-form.button-submit">
                <Button.Default
                  disabled={!dirty || isLoading}
                  icon={Icon.LeftChevron}
                  text="Send"
                  onClick={() => handleSubmit()}
                />
              </FormButtonBar>
            </FormWrapper>
          )
        }}
      </Formik>
    </FormSection>
  )
}

export default FormContainer
