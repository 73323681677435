import React, { useState, useMemo, useCallback } from 'react'
import { useRouter } from 'next/router'
import { Button, Heading, Paragraph, theme } from '@damen/ui'
import styled from 'styled-components'
import { render } from 'storyblok-rich-text-react-renderer'

import { useMutation } from '@apollo/client'
import { useBasePath } from '@/hooks'
import Details from '@/components/Forms/DepartmentContact/Details'
import Form from '@/components/Forms'
import Modal from '@/components/UI/Modal'
import { pushToDataLayer } from '@/lib/gtm'
import SuccessContent from '../SuccessContent'
import { ModalDepartmentContactContent } from '@/queries/schema.generated'
import {
  DepartmentContactFormDocument,
  DepartmentContactFormMutation,
} from '@/queries/mutations/DepartmentContactFormMutation.generated'
import { DEPARTMENT_CONTACT_QUERYSTRING, FORMTYPE } from '@/components/Forms/constants'
import { FormContent } from '../styles'

interface Props {
  id: string
  formName: string
  content?: ModalDepartmentContactContent
  showModal: boolean
  handleCloseModal: () => void
}

const mapFormData = (content: any) => {
  if (!content) return null
  return {
    options: content?.interestOptions?.map((option: any) => {
      return {
        value: option.value,
        label: option.title,
      }
    }),
    optionsActiveContent: content?.interestOptions
      ?.map((option: any) => {
        return {
          id: option.value,
          activeContent: option.activeContent,
        }
      })
      .filter((option: any) => !!option?.activeContent),
    labels: {
      company: content?.company?.label,
      countries: content?.country?.label,
      countryOfOperation: content?.countryOfOperation?.label,
      email: content?.email?.label,
      firstname: content?.firstname?.label,
      lastname: content?.lastname?.label,
      message: content?.textArea?.label,
      phone: content?.phonenumber?.label,
      subject: content?.interest?.label,
      subscribe: content?.subscribe?.label,
    },
    placeholders: {
      company: content?.company?.placeholder,
      countries: content?.country?.placeholder,
      email: content?.email?.placeholder,
      firstname: content?.firstname?.placeholder,
      lastname: content?.lastname?.placeholder,
      message: content?.textArea?.placeholder,
      phone: content?.phonenumber?.placeholder,
      subject: content?.interest?.placeholder,
    },
    errors: {
      countryOfOperation: content?.countryOfOperation?.error,
      country: content?.country?.error,
      subject: content?.interest?.error,
      email: content?.email?.error,
      firstname: content?.firstname?.error,
      lastname: content?.lastname?.error,
    },
    validations: {
      phone: content?.phonenumber?.validation,
      email: content?.email?.validation,
    },
    extraLabel: {
      phone: content?.phonenumber?.extraLabel,
    },
    genericValidations: {
      containsUrl: content?.genericValidations?.containsUrl,
    },
    richText: {
      disclaimer: content?.disclaimer?.text ? render(JSON.parse(content?.disclaimer?.text)) : undefined,
    },
    ...content,
  }
}

const DepartmentContact: React.FC<React.PropsWithChildren<Props>> = ({
  id: sidebarId,
  formName,
  content,
  showModal,
  handleCloseModal,
}) => {
  const router = useRouter()
  const { url } = useBasePath()
  const [completed, setCompleted] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  const formData = useMemo(() => mapFormData(content), [content])
  const currentUrl = url ?? ''

  const [executeMutation, { data, loading, error }] =
    useMutation<DepartmentContactFormMutation>(DepartmentContactFormDocument)

  const isDataSuccess = data?.departmentContactForm.code === 200 && !error

  const onFormSubmit = useCallback(
    (values: any) => {
      const payload = () => ({
        ...values,
        currentUrl,
        ...(retryCount > 0 && { retryCount }),
      })
      executeMutation({
        variables: { id: values.subject, sidebarId, formName, payload: payload() },
      })
        .then((resp) => {
          if (resp.data?.departmentContactForm.code === 200) {
            setCompleted(true)
            return pushToDataLayer({ event: 'form_submit', 'contact-interest': values.subject, type: 'get_in_contact' })
          }

          handleFormError();
        })
        .catch(() => {
          handleFormError()
        })
    },
    [executeMutation, sidebarId, formName, currentUrl, retryCount],
  )

  const handleFormError = () => {
    setCompleted(true);
  }

  const closeModal = useCallback(() => {
    handleCloseModal()
    if (router.asPath.includes(DEPARTMENT_CONTACT_QUERYSTRING)) {
      localStorage.removeItem(FORMTYPE)
      router.back()
    }
    setCompleted(false);
  }, [handleCloseModal, router])

  const retryForm = useCallback(() => {
    setRetryCount(retryCount + 1)
    window.grecaptcha?.reset()
    setCompleted(false);
  }, [retryCount])

  return (
    content && (
      <Modal.Side
        isOpen={showModal}
        testId="department-modal-contact"
        onClose={closeModal}
        extraPanelContent={
          content.departmentContactDetails ? (
            <Details departmentContactDetails={content.departmentContactDetails} showBowWave={content.showBowWave} />
          ) : null
        }
      >
        <FormContent show={!completed}>
          <TitleWrapper>
            <Title>{content.title}</Title>
            {content.subtitle && <Subtitle>{content.subtitle}</Subtitle>}
          </TitleWrapper>
          <Form.DepartmentContactForm
            formData={formData}
            isLoading={loading}
            handleOnSubmit={onFormSubmit}
            handleOnError={handleFormError}
          />
        </FormContent>

        <SuccessContent
          image={isDataSuccess ? content.success.image : content.error.image}
          showBowWave={content.showBowWave}
          show={completed}
        >
          <Heading.HeadingTwo>{isDataSuccess ? content.success.title : content.error.title}</Heading.HeadingTwo>
          <Paragraph.Default>{isDataSuccess ? content.success.text : content.error.text}</Paragraph.Default>
          <Button.Default
            testId="button-contact-close"
            text={isDataSuccess ? content.success.buttonText : content.error.buttonText}
            onClick={isDataSuccess ? closeModal : retryForm}
          />
        </SuccessContent>
      </Modal.Side>
    )
  )
}

const TitleWrapper = styled.div`
  margin: 0 0 32px;
`

const Title = styled(Heading.HeadingFour)`
  margin: 0 0 8px;
`

const Subtitle = styled.div`
  color: ${theme.colors.grey};
  font-family: ${theme.fonts.body.style.fontFamily};
  font-size: 14px;
  font-weight: ${theme.typography.fontWeightRegular};
`

export default DepartmentContact
