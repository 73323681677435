import React, { useRef } from 'react'
import { Formik, Form as FormWrapper } from 'formik'
import { Button, Form, Icon } from '@damen/ui'
import ReCAPTCHA from 'react-google-recaptcha'
import Yup from '@/components/Forms/defaultValidations'
import { DownloadFormLocalStorageDataProps } from '@/components/Forms/Download/types'
import { getPublicEnv } from '@/utils/env'
import { FormDisclaimer, FormSection, FormFieldWrapper, FormField, FormButtonBar } from '../styles'

interface Props {
  formData: any
  initialValues?: DownloadFormLocalStorageDataProps
  isLoading: boolean
  handleOnSubmit: (values: any) => void
  handleOnError: () => void
  stickyButton?: boolean
}

const FormContainer: React.FC<React.PropsWithChildren<Props>> = ({
  formData,
  isLoading,
  handleOnSubmit,
  handleOnError,
  initialValues,
  stickyButton = true,
}) => {
  const reCaptchaRef = useRef(null)
  const DownloadSchema = Yup.object().shape({
    email: Yup.string().email(formData.validations.email).required(formData.errors.email),
    firstname: Yup.string().required(formData.errors.firstname).noUrl(formData.genericValidations.containsUrl),
    lastname: Yup.string().required(formData.errors.lastname).noUrl(formData.genericValidations.containsUrl),
  })

  return (
    <FormSection>
      <Formik
        initialValues={{
          email: initialValues?.email ?? '',
          firstname: initialValues?.firstname ?? '',
          lastname: initialValues?.lastname ?? '',
          subscribeNewsletter: initialValues?.subscribeNewsletter ?? false,
          gRecaptchaResponse: '',
        }}
        validationSchema={DownloadSchema}
        onSubmit={async (values) => {
          try {
            const token = await reCaptchaRef.current.executeAsync()
            const valuesToSend = { ...values, gRecaptchaResponse: token }
            handleOnSubmit(valuesToSend); return;
          } catch (error) {
            handleOnError(); return;
          }
        }}
      >
        {({ dirty, errors, touched, values, handleChange, handleSubmit }) => {
          return (
            <FormWrapper data-testid="download-form">
              <FormFieldWrapper>
                <FormField data-testid="download-form.firstname">
                  <Form.Input
                    error={errors.firstname || ''}
                    name="firstname"
                    placeholder={formData.placeholders.firstname}
                    touched={touched.firstname}
                    label={`${formData.labels.firstname} *`}
                    value={values.firstname}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField data-testid="download-form.lastname">
                  <Form.Input
                    error={errors.lastname || ''}
                    name="lastname"
                    placeholder={formData.placeholders.lastname}
                    touched={touched.lastname}
                    label={`${formData.labels.lastname} *`}
                    value={values.lastname}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField data-testid="download-form.email">
                  <Form.Input
                    error={errors.email || ''}
                    name="email"
                    value={values.email}
                    touched={touched.email}
                    label={`${formData.labels.email} *`}
                    placeholder={formData.placeholders.email}
                    onChange={handleChange}
                    type="email"
                  />
                </FormField>
                <FormField data-testid="download-form.subscribe">
                  <Form.Checkbox
                    background="blue"
                    error={errors.subscribeNewsletter}
                    touched={touched.subscribeNewsletter}
                    name="subscribeNewsletter"
                    label={formData.labels.subscribe}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey={getPublicEnv('NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY')}
                    size="invisible"
                    badge="inline"
                  />
                </FormField>
              </FormFieldWrapper>
              {formData.richText?.disclaimer && <FormDisclaimer>{formData.richText?.disclaimer}</FormDisclaimer>}
              <FormButtonBar stickyButton={stickyButton} data-testid="download-form.button-submit">
                <Button.Default
                  text={formData.labels.buttonText}
                  onClick={() => handleSubmit()}
                  icon={Icon.LeftChevron}
                  disabled={(!dirty || isLoading) && !initialValues}
                />
              </FormButtonBar>
            </FormWrapper>
          )
        }}
      </Formik>
    </FormSection>
  )
}

export default FormContainer
