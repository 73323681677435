import { styled } from 'styled-components'
import { Action, Form, Heading, ObfuscateLink, theme } from '@damen/ui'

export const TitleWrapper = styled.div`
  margin: 0 0 32px;
`

export const Title = styled(Heading.HeadingFour)`
  margin: 0;
`

export const Subtitle = styled(Heading.HeadingFour)`
  margin: 0 0 16px;
`

export const AddressContent = styled.div`
  margin-top: 40px;
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  button {
    margin-top: 8px;
    font-size: 14px;
  }
`

export const Address = styled.div`
  margin-bottom: 24px;
`

export const Label = styled(Form.Label)`
  text-transform: none;
  margin: 0 0 8px;
  font-size: 14px;
`

export const Detail = styled.div`
  & + & {
    margin-top: 24px;
  }
`

export const Value = styled.div`
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 1.71;
  font-family: ${theme.fonts.body.style.fontFamily};
`

export const Link = styled.a`
  ${Action.LinkStyle}
`

export const StyledObfuscate = styled(ObfuscateLink)`
  ${Action.LinkStyle}
`

export const Footer = styled.div`
  margin-top: auto;
  padding-top: 40px;
`
